import React, { useCallback, useState, useRef, useMemo, useEffect, useLayoutEffect } from 'react';
import { Resizable, ResizeCallbackData } from 'react-resizable';
import Icon, { HolderOutlined, LockOutlined, UnlockOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import {
	NoteWidgetState,
	getDefaultState
} from '@kemu-io/kemu-core/widgets/note/index.js';
import classNames from 'classnames';
import 'react-resizable/css/styles.css';
import { Crepe } from '@milkdown/crepe';
import tinycolor from 'tinycolor2';
import { Milkdown, MilkdownProvider, useEditor } from '@milkdown/react';
import { clipboard } from '@milkdown/plugin-clipboard';
import { WidgetState } from '@kemu-io/kemu-core/types';
import { editorStateCtx, editorViewCtx } from '@milkdown/core';
import {
	GetPortsInformationFunction,
	GateUI,
	GateUIProps,
} from '../index.ts';
import GateIcon from '../../gateIcon/gateIcon.tsx';
import { ReactComponent as NoteWidgetIcon } from './icon.svg';
import styles from './note.module.css';
import useReactiveWidgetState from '@common/hooks/useReactiveWidgetState.ts';
import { ABORT_DRAGGING_CLASS } from '@common/constants.ts';
import ResizeHandle from '@components/ResizeHandle/ResizeHandle.tsx';
import '@milkdown/crepe/theme/common/style.css';
import '@milkdown/crepe/theme/frame.css';
import ColorSwatch from '@components/colorSwatch/colorSwatch.tsx';

const DEFAULT_W = 300;
const DEFAULT_H = 200;

const defaultColors = [
	'#42a5f5', '#ab47bd', '#626060',
	'#68bb6c', '#eac43e', '#ff7070',
	'#CE69FC', '#FF6822', '#FF9361',
	'#FFB834', '#02AF97', '#777898',
	'#7CE898', '#FF8CA7', '#0291AF',
	'#0094DF', '#84D2F9', '#00B9DF',
	'#A9B8C2', '#A15D1A', '#20282E'
];

const MilkdownEditor = ({ content, onChange, readOnly }: { content: string, onChange: (markdown: string) => void, readOnly: boolean }) => {

	const { get } = useEditor((root) => {

		const crepe = new Crepe({
			root,
			defaultValue: content,
			features: {
				[Crepe.Feature.Latex]: false,
			},
			featureConfigs: {
				[Crepe.Feature.ImageBlock]: {

					// Intercept image uploads and convert them to base64
					onUpload: async (file) => {
						if (!file.type.includes('image')) { return ''; }

						// Convert image to base64
						const reader = new FileReader();
						const base64 = await new Promise<string>((resolve) => {
							reader.onload = () => resolve(reader.result as string);
							reader.readAsDataURL(file);
						});

						return base64;
					},

				}
			}
		});

		crepe.on((listener) => {
			listener.markdownUpdated((ctx, markdown, prevMarkdown) => {
				const view = ctx.get(editorViewCtx);
				const isEditable = view.props.editable?.(ctx.get(editorStateCtx));
				if (markdown !== prevMarkdown && isEditable) {
					onChange(markdown);
				}
			});
		});

		crepe.editor.use(clipboard);

		return crepe;
	});


	// This is an attempt to preserve the empty lines in the editor by adding a non-breaking space before each newline
	// it doesn't seem to work consistently.
	useEffect(() => {
		const editor = get();
		if (!editor) { return; }

		const view = editor.ctx.get(editorViewCtx);

		// Make the editor editable or not
		view.setProps({ editable: () => !readOnly });


		// if (readOnly) {
		// 	const markdown = getMarkdown();
		// 	const content = markdown(editor.ctx);

		// 	const fixedDoc = content.replace(/^\n$/gm, '&nbsp;\n');
		// 	console.log(`Fixed doc: "${fixedDoc}"`);
		// 	editor?.action(replaceAll(fixedDoc, true));
		// }
	}, [get, readOnly]);

	return <Milkdown />;
};

const NoteWidget = (props: GateUIProps): React.JSX.Element => {
	const { repaint } = props;
	const [state, setState] = useReactiveWidgetState<NoteWidgetState>(props.recipeId, props.thingRecipeId, props.info.id);
	const [showColorPicker, setShowColorPicker] = useState(false);
	const fixedState: NoteWidgetState = {
		...getDefaultState(),
		...state
	};

	const insideCustomWidget = props.info.groupId !== undefined;
	// New notes appear unlocked, while notes loaded from a recipe will be locked by default
	const justAddedToCanvas = fixedState.locked === undefined;
	const [isEditing, setIsEditing] = useState(justAddedToCanvas || !fixedState.locked);

	const { bgColor, iconColor } = useMemo(() => {
		const bgColor = tinycolor(fixedState.color).setAlpha(0.1).toString();
		const iconColor ='#000';
		return { bgColor, iconColor };
	}, [fixedState.color]);

	const handleResize = useCallback((event: React.SyntheticEvent<Element, Event>, data: ResizeCallbackData) => {
		setState(current => ({
			...current,
			size: {
				width: data.size.width,
				height: data.size.height
			}
		}));

		repaint();
	}, [repaint, setState]);

	const onColorChange = useCallback((color: string) => {
		setState(current => ({
			...current,
			color,
		}));

		setShowColorPicker(false);
	}, [setState]);

	const handleTextChange = useCallback((value: string) => {
		setState(current => ({
			...current,
			locked: false,
			text: value
		}));
	}, [setState]);

	const toggleColorPicker = () => {
		setShowColorPicker(!showColorPicker);
	};

	const toggleEditMode = () => {
		const next = !isEditing;

		setState(current => ({
			...current,
			locked: !next
		}));

		setIsEditing(next);
	};


	return (
		<Resizable
			height={fixedState.size?.height || DEFAULT_H}
			width={fixedState.size?.width || DEFAULT_W}
			minConstraints={[DEFAULT_W, DEFAULT_H]}
			onResize={handleResize}
			resizeHandles={isEditing ? ['se'] : []}
			handle={<ResizeHandle className={classNames(ABORT_DRAGGING_CLASS, styles.ResizeHandle)} />}
		>
			<div
				className={classNames(styles.GateBody, {
					[styles.Editing]: isEditing
				})}
				style={{
					width: (fixedState.size?.width || DEFAULT_W),
					height: (fixedState.size?.height || DEFAULT_H),
					border: `2px solid ${fixedState.color}`
				}}
			>
				{isEditing && (
					<div className={styles.FloatingColorMenu}>
						<ColorSwatch
							className={styles.Swatch}
							width={'350px'}
							popupClassName={styles.SwatchPopup}
							color={fixedState.color}
							colors={defaultColors}
							onColorChange={onColorChange}
							showColorPicker={showColorPicker}
						/>
					</div>
				)}

				<div
					style={{
						backgroundColor: isEditing ? bgColor : 'transparent',
						borderColor: fixedState.color,
						color: iconColor,
						fill: iconColor
					}}
					className={classNames(styles.Toolbar, {
						[styles.NotEditing]: !isEditing,
						[ABORT_DRAGGING_CLASS]: !isEditing
					})}
				>
					{isEditing && (
						<>
							<div className={styles.DragIcon}>
								<HolderOutlined />
							</div>
							<Button
								type="text"
								onClick={toggleColorPicker}
								icon={<Icon  component={() => <div style={{ backgroundColor: fixedState.color }} className={styles.ColorPickerBtn}/>} />}
							/>
						</>
					)}

					<Button
						type="text"
						className={styles.SettingsBtn}
						icon={isEditing ? <UnlockOutlined /> : <LockOutlined />}
						onClick={toggleEditMode}
					/>
				</div>

				<div
					className={classNames(styles.EditorContainer, ABORT_DRAGGING_CLASS, {
						[styles.NotEditing]: !isEditing,
						[styles.InCustomWidget]: insideCustomWidget
					})}
					style={{
						backgroundColor: bgColor,
					}}
				>
					<MilkdownProvider>
						<MilkdownEditor
							content={fixedState.text || ''}
							onChange={handleTextChange}
							readOnly={!isEditing}
						/>
					</MilkdownProvider>
				</div>
			</div>
		</Resizable>
	);
};

/** Icon to be added to the bar */
const GateBarIcon = (): React.JSX.Element => {
	return (
		<GateIcon
			icon={<Icon component={NoteWidgetIcon} />}
		/>
	);
};

const getPortsInformation: GetPortsInformationFunction = () => {
	return {
		inputs: [],
		outputs: [],
	};
};

export default {
	getPortsInformation,
	hasTitle: false,
	BarIcon: GateBarIcon,
	getWrapperClass: () => styles.WrapperClass,
	getSelectionColor: (state: Readonly<WidgetState<NoteWidgetState>>) => state.color || 'var(--kemu-color-widget-type-custom)',
	Element: NoteWidget,
	getGatesBarTitle: (intl) => intl.formatMessage({ id: 'LogicMaker.Gates.Note.Title' }),
} as GateUI;
