import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RecipeType } from '@kemu-io/kemu-types';
import Icon from '@ant-design/icons';
import EditableHeading from '../form-control/editableHeading/editableHeading';
import {
	setRecipeName,
	currentRecipeTitle,
	selectCurrentRecipe,
} from '../../features/Workspace/workspaceSlice';
import { useTranslation } from '../../common/hooks/index';
import { ReactComponent as CloudIcon } from './cloud-storage.svg';
import { ReactComponent as DiskIcon } from './file-storage.svg';
import styles from './RecipeWorkspaceTitle.module.css';
export interface MenuInfo {
	key: string;
	keyPath: string[];
	/** @deprecated This will not support in future. You should avoid to use this */
	item: React.ReactInstance;
	domEvent: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>;
}

const RecipeWorkspaceTitle = (): React.JSX.Element => {
	const dispatch = useDispatch();
	const t = useTranslation('Recipe');
	const defaultRecipeTitle = t('DefaultTitle', 'Untitled Recipe');
	const recipeTitle = useSelector(currentRecipeTitle);
	const currentRecipe = useSelector(selectCurrentRecipe);

	const handleTitleChange = (text: string) => {
		dispatch(setRecipeName(text));
	};

	const renderIcon = () => {
		// Don't show any icon if it's a default recipe that hasn't been saved yet
		if (currentRecipe.isDefault) {
			return null;
		}

		// Show cloud icon for browser recipes that have been saved to the cloud
		if (currentRecipe.type === RecipeType.Browser && currentRecipe.entityInfo?.dbId) {
			return <Icon component={CloudIcon} className={styles.StorageIcon} />;
		}

		// Show disk icon for desktop recipes
		if (currentRecipe.type === RecipeType.Desktop) {
			return <Icon component={DiskIcon} className={styles.StorageIcon} />;
		}

		return null;
	};

	return (
		<div className={styles.TitleContainer}>
			{renderIcon()}
			<EditableHeading
				onEditFinished={handleTitleChange}
				tag="h1"
				text={recipeTitle || defaultRecipeTitle}
				className={styles.Header}
			/>
		</div>
	);
};

export default RecipeWorkspaceTitle;
