import React from 'react';
import Icon from '@ant-design/icons/lib/components/Icon';
import styles from './AnimatedUpdateIcon.module.css';
import { ReactComponent as UpdateIcon } from '@src/assets/img/menus/update-available.svg';

const AnimatedUpdateIcon = () => {
  return (
    <Icon component={UpdateIcon} className={styles.Icon} />
  );
};

export default AnimatedUpdateIcon;
