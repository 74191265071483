import { safeJsonParse } from './utils';

type PrimitiveValues = string | number | boolean | null;
type SerializableValue = PrimitiveValues | PrimitiveValues[] | Record<string, PrimitiveValues>;
type SerializableObject = Record<string, SerializableValue>;

const getStoredObject = <T=SerializableObject>(key: string, defaultValue: T): T => {
  const str = localStorage.getItem(key);
  const settings = safeJsonParse<T>(str || '');
  return {
    ...defaultValue,
    ...settings,
  };
};

const updateObject = (key: string, value: SerializableObject | null): void => {
  if (!value) {
    localStorage.removeItem(key);
  } else {
    localStorage.setItem(key, JSON.stringify(value));
  }
};

export default {
  getStoredObject,
  updateObject,
};
