import { createSlice, createAsyncThunk, PayloadAction, ActionReducerMapBuilder } from '@reduxjs/toolkit';
import { AnnotationDataTypes, AnnotationTextData, KemuAnnotation, Position } from '@kemu-io/kemu-core/types';
import { RootState } from '../../app/store';
import { AsyncState, AsyncRequestStatus, SelectedBlockInfo } from '../../types/core_t';
import * as recipeApi from '../../api/recipe/recipeApi';
import { storeRecipeReducer } from './reducers/storeRecipeReducer';
import { uploadRecipeStorageReducer } from './reducers/uploadRecipeStorageReducer';
import { setRecipeUploadProgressReducer } from './reducers/setuploadProgressReducer';
import { downloadRecipeActionReducer } from './reducers/downloadRecipeReducer';
import { saveRecipeToFileReducer } from './reducers/saveRecipeToFileReducer';
import localStorageManager from '@common/localStorageManager';

interface AsyncOperationDetails extends AsyncState {
	action?: string;
	entityId?: string;
	extra?: unknown;
}

export type NotificationInfo = {
	/** optional unique error code */
	code?: string;
	message: string;
	title?: string;
	duration?: number;
	type: 'error' | 'success' | 'info' | 'warning';
}

interface AsyncOperationWithProgress extends AsyncOperationDetails {
	contentProgress?: number;
	storageProgress?: number;
}

export interface SettingsPanelInfo {
	block: SelectedBlockInfo
}

export interface PhoneInfo {
	name: string;
}

export type UserActions = {
	gettingStartedClosed: boolean;
}

export type LogicMapperSettings = {
	portInspectorVisible: boolean;
	showConnectionAnimations: boolean;
	showWidgetProcessingAnimations: boolean;
	showSuccessTick: boolean;
	showDebuggingPanel: boolean;
	/** Position of the debugging panel */
	debuggingPanelPosition: Position;
	/** Position of the port inspector */
	portInspectorPosition: Position;
}

type TutorialInfo = {
	isDraft: boolean;
	id: string;
	notificationShown: boolean;
	step: number;
}

const logicMapperSettingsKey = 'logicMapperSettings';

export interface InterfaceState {
	newVersionAvailable: boolean;
	saveAsModalVisible: boolean;
	asyncOperation: AsyncOperationDetails | null;
	recipeUploadDetails: (AsyncOperationWithProgress & { hasStorage?: boolean }) | null;
	recipeDownloadDetails: AsyncOperationWithProgress | null;
	twilioConnectionToken: string | null;
	connectedPhone: PhoneInfo | null;
	// activeTutorialInfo: TutorialInfo | null;
	annotations: Record<string, KemuAnnotation<AnnotationDataTypes>>;
	logicMapperSettings: LogicMapperSettings;
	/** Will contain the checksum of the combined storage disk (if any) */
	lastStorageChecksum: string | null;

	settingsPanel: SettingsPanelInfo | null;
	userActions: UserActions;
	notification: NotificationInfo | null;
}

const initialState: InterfaceState = {
	newVersionAvailable: false,
	saveAsModalVisible: false,
	logicMapperSettings: localStorageManager.getStoredObject<LogicMapperSettings>(logicMapperSettingsKey, {
		portInspectorVisible: true,
		showConnectionAnimations: true,
		showWidgetProcessingAnimations: true,
		showSuccessTick: true,
		showDebuggingPanel: true,
		debuggingPanelPosition: {
			x: 20,
			y: 65,
		},
		portInspectorPosition: {
			x: 20,
			y: 135,
		},
	}),
	recipeUploadDetails: null,
	recipeDownloadDetails: null,
	asyncOperation: null,
	lastStorageChecksum: null,
	twilioConnectionToken: null,
	connectedPhone: null,
	settingsPanel: null,
	// activeTutorialInfo: null,
	notification: null,
	annotations: {},
	userActions: {
		gettingStartedClosed: false,
	}
};


const removeRecipe = createAsyncThunk('/interface/removeRecipe', async (props: {recipeId: string, onSuccess?: () => void}): Promise<string | null> => {
	await recipeApi.deleteRecipe(props.recipeId);
	props.onSuccess?.();
	// To reset the storage checksum
	return null;
});


/**
 * Reduces builder plate for basic async actions that just show an indicator
 * @param builder 
 * @param thunk 
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const buildAsyncReducerBoilerplate = (builder: ActionReducerMapBuilder<InterfaceState>, thunk: any) => {
	builder.addCase(thunk.pending, (state) => {
		state.asyncOperation = {
			...state.asyncOperation,
			status: AsyncRequestStatus.loading,
			error: undefined
		};
	});

	builder.addCase(thunk.fulfilled, (state, action: PayloadAction<string | null >) => {
		state.asyncOperation = { ...state.asyncOperation, status: AsyncRequestStatus.completed };
		state.lastStorageChecksum = action.payload;
	});

	builder.addCase(thunk.rejected, (state, action) => {
		state.asyncOperation = {
			...state.asyncOperation,
			status: AsyncRequestStatus.error,
			error: action.error
		};
	});
};

export const interfaceSlice = createSlice({
	name: 'workspace',
	initialState,
	reducers: {
		setRecipeUploadProgress: setRecipeUploadProgressReducer,

		showGlobalNotification: (state, action: PayloadAction<NotificationInfo | null>) => {
			state.notification = action.payload;
		},

		setNewVersionAvailable: (state) => {
			state.newVersionAvailable = true;
		},

		/** 
		 * Changes the current details of the tutorial. 
		 * Only the provided properties will be replaced.
		 **/
		// setActiveTutorialInfo: (state, action: PayloadAction<TutorialInfo | null>) => {
		// 	state.activeTutorialInfo = action.payload;
		// },

		setConnectedPhoneInfo: (state, action: PayloadAction<PhoneInfo | null>) => {
			state.connectedPhone = action.payload;
		},

		setTwilioConnectionToken: (state, action: PayloadAction<string>) => {
			state.twilioConnectionToken = action.payload;
		},

		setBlockForSettingsPanel: (state, action: PayloadAction<SettingsPanelInfo | null>) => {
			state.settingsPanel = action.payload;
		},

		setLogicMapperSettingValue: (state, action: PayloadAction<{key: keyof LogicMapperSettings, value: LogicMapperSettings[keyof LogicMapperSettings]}>) => {
			const newSettings: LogicMapperSettings = {
				...state.logicMapperSettings,
				[action.payload.key]: action.payload.value
			};

			state.logicMapperSettings = newSettings;
			localStorageManager.updateObject(logicMapperSettingsKey, newSettings);
		},

		// setShowPortDebugPanel: (state, action: PayloadAction<boolean>) => {
		// 	const newSettings: LogicMapperSettings = {
		// 		...state.logicMapperSettings,
		// 		debugPanelVisible: action.payload
		// 	};

		// 	state.logicMapperSettings = newSettings;
		// 	localStorageManager.updateObject(logicMapperSettingsKey, newSettings);
		// },

		setAsyncOperationDetails: (state, action: PayloadAction<Partial<AsyncOperationDetails>>) => {
			const details = {
				...state.asyncOperation,
				...action.payload
			};

			state.asyncOperation = <AsyncOperationDetails> details;
		},

		showSaveAsDialog: (state, action: PayloadAction<boolean>) => {
			state.saveAsModalVisible = action.payload;
		},

		setUserAction: (state, action: PayloadAction<{ action: keyof UserActions, value: boolean }>) => {
			state.userActions = {
				...state.userActions,
				[action.payload.action]: action.payload.value
			};
		},

		setRecipeDownloadProgress: (state, action: PayloadAction<{content?: number; storage?: number}>) => {
			if (state.recipeDownloadDetails) {
				if (action.payload.content) {
					state.recipeDownloadDetails.contentProgress = action.payload.content;
				}
				if (action.payload.storage) {
					state.recipeDownloadDetails.storageProgress = action.payload.storage;
				}
			}
		}
	},

	extraReducers: (builder) => {
		downloadRecipeActionReducer(builder);
		storeRecipeReducer(builder);
		uploadRecipeStorageReducer(builder);
		buildAsyncReducerBoilerplate(builder, removeRecipe);
	}
});


export const {
	showSaveAsDialog,
	setRecipeUploadProgress,
	setRecipeDownloadProgress,
	setAsyncOperationDetails,
	setBlockForSettingsPanel,
	setTwilioConnectionToken,
	setConnectedPhoneInfo,
	// setActiveTutorialInfo,
	setNewVersionAvailable,
	setUserAction,
	setLogicMapperSettingValue,
	showGlobalNotification,
} = interfaceSlice.actions;

export {
	removeRecipe,
};


export const selectSaveAsModalVisible = (state: RootState): boolean => state.interface.saveAsModalVisible;
export const asyncOperationState = (state: RootState): AsyncOperationDetails | null => state.interface.asyncOperation;
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const selectRecipeUploadDetails = (state: RootState) => state.interface.recipeUploadDetails;
export const selectRecipeDownloadDetails = (state: RootState): AsyncOperationWithProgress | null => state.interface.recipeDownloadDetails;
export const settingsPanelDetails = (state: RootState): SettingsPanelInfo | null => state.interface.settingsPanel;
export const twilioConnectionToken = (state: RootState): string | null => state.interface.twilioConnectionToken;
export const connectedPhone = (state: RootState): PhoneInfo | null => state.interface.connectedPhone;
export const newVersionAvailable = (state: RootState): boolean => state.interface.newVersionAvailable;

export const selectNotification = (state: RootState): NotificationInfo | null => state.interface.notification;
/** Indicates if there is a tutorial linked to the current recipe */
// export const activeTutorialInfo = (state: RootState): TutorialInfo | null => state.interface.activeTutorialInfo || null;

export const selectAnnotations = (state: RootState): Record<string, KemuAnnotation<AnnotationDataTypes>> => state.interface.annotations;
export const selectAnnotationById = <T extends AnnotationDataTypes = AnnotationTextData>(id: string) => (state: RootState): KemuAnnotation<T> | null => {
	// @ts-expect-error will be fixed later // FIXME: bad typings!
	// https://itnext.io/typescript-generics-the-easy-way-1a8aff7f4151
	return state.interface.annotations[id] as T || null;
};

export const selectUserActions = (state: RootState): Readonly<UserActions> => state.interface.userActions;
export const selectLogicMapperSettings = (state: RootState): LogicMapperSettings => state.interface.logicMapperSettings;
export default interfaceSlice.reducer;


