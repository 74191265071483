import executionTracker, { type ExecutionError } from '@kemu-io/kemu-core/common/executionTracker';
import { utils } from '@kemu-io/kemu-core/common';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { DEFAULT_THING_ID } from '@kemu-io/kemu-core/common/constants';
import { HubServiceState, Position, WidgetState, WidgetType } from '@kemu-io/kemu-core/types';
import { selectCurrentRecipe } from '@src/features/Workspace/workspaceSlice';
import { getWidgetsInThing } from '@src/app/recipe/utils';

export type ProcessorException = ExecutionError & {
  widgetInfo: {
    id: string;
    type: WidgetType;
    groupId?: string;
    position: Position;
    /** Name of the source port (from the parent widget) that triggered the error */
    sourcePortName: string;
    /** Name of the target port (from the child widget) that triggered the error */
    targetPortName: string;
    readonly state: WidgetState;
    /** Provides extra details about the hub service that triggered the error */
    hubService?: {
      name: string;
      title?: string;
      version: string;
    }
  }
}

const getHubServiceDetails = (state: WidgetState): ProcessorException['widgetInfo']['hubService'] | null => {
  const hubServiceState = state as HubServiceState;
  const isVariant = !!hubServiceState.variantId;
  const variantInfo = isVariant ? (hubServiceState.service?.variants || []).find((v) => v.id === hubServiceState.variantId) : null;

  if (!hubServiceState.service) { return null; }

  const title = variantInfo?.name || hubServiceState.service.title || '';
  const name = variantInfo?.name || hubServiceState.service.name;
  const version = hubServiceState.service.version;

  return {
    name,
    title,
    version,
  };
};

/**
 * Detects when a processing error occurs.
 * @returns The error that occurred, or null if no error has occurred
 */
const useProcessingErrorDetection = (): [ProcessorException | null, (() => void)] => {
  const [error, setError] = useState<ProcessorException | null>(null);
  const recipe = useSelector(selectCurrentRecipe);

  const clearError = useCallback(() => {
    setError(null);
  }, []);

  useEffect(() => {
    if (!recipe.poolId) { return; }

    const unsubscribe = executionTracker.onErrorEvent((e) => {
      const widgetId: string = e.targetWidgetId;
      const currentWidgets = getWidgetsInThing(recipe?.poolId || '', DEFAULT_THING_ID);
      const widget = currentWidgets[widgetId];

      if (!widget) {
        console.error(`Exception on unknown widget ${widgetId}: `, e);
        return;
      }

      const readonlyState = Object.freeze({ ...widget.state });
      const isHubService = widget.type === WidgetType.hubService;
      const hubService = isHubService ? getHubServiceDetails(readonlyState) : null;

      const exception: ProcessorException = {
        ...e,
        widgetInfo: {
          type: widget.type,
          id: widgetId,
          groupId: widget.groupId,
          position: widget.canvas.position,
          state: readonlyState,
          sourcePortName: e.sourcePortName,
          targetPortName: e.targetPortName,
          hubService: hubService || undefined,
        }
      };

      setError(exception);
    });

    return () => {
      unsubscribe();
    };
  }, [recipe.poolId]);


  return [
    error,
    clearError,
  ];
};

export default useProcessingErrorDetection;
