import { useCallback, useEffect, useMemo, useState } from 'react';
import { HubSystemInfo, KemuHubFunctions } from '@kemu-io/hs-types';
import { useDebouncedCallback } from 'use-debounce';
import { message } from 'antd';
import useKemuHubLink from './useHubLink';
import useInvokeTimeout from './useInvokeTimeout';
import useTranslation from './useTranslation';
import Logger from '@common/logger.ts';

const log = Logger('useHubUpdateDetector');
// 1 minute
const UpdateCheckInterval = 1000 * 60;

type UpdateInfo = HubSystemInfo['updateAvailable'];

/**
 * @returns the information about the system the hub is running on.
 */
const useHubUpdateDetector = () => {
  const { connector, status } = useKemuHubLink();
  const [updateInfo, setUpdateInfo] = useState<UpdateInfo | null>();
  const isAcknowledged = status === 'acknowledged';
  const invokeTimeout = useInvokeTimeout();
  const t = useTranslation('HubUpdateDetector');

  const rebootToInstall = useCallback(() => {
    connector.executeHubFunction(KemuHubFunctions.RebootToInstallUpdate, []).catch(res => {
      message.error(
        res.errCode === 'GUI_NOT_SUPPORTED' ? t.withBaseKey('Error')('GuiNotSupported') : t('Error')
      );
    });
  }, [connector, t]);

  const checkForUpdates = useDebouncedCallback(() => {
    connector.getHubSystemInfo(true).then((info) => {
      setUpdateInfo(info?.updateAvailable || null);
    }).catch(err => {
      log.error('Failed to get system info', err);
      setUpdateInfo(null);
    }).finally(() => {
      invokeTimeout(checkForUpdates, UpdateCheckInterval);
    });
  }, 500);

  useEffect(() => {
    if (isAcknowledged) {
      checkForUpdates();
    }

    return () => {
      checkForUpdates.cancel();
    };
  }, [invokeTimeout, checkForUpdates, isAcknowledged]);

  useEffect(() => {
    if (updateInfo && status !== 'acknowledged') {
      setUpdateInfo(null);
    }
  }, [updateInfo, status]);

  return {
    updateInfo,
    rebootToInstall,
  };
};

export default useHubUpdateDetector;
