import React, { forwardRef } from 'react';
import Icon from '@ant-design/icons';
import classNames from 'classnames';
import { ReactComponent as ResizeIcon } from './resize-icon.svg';
import styles from './ResizeHandle.module.css';

interface Props {
  handleAxis?: string;
  className?: string;
}

const ResizeHandle = forwardRef<HTMLDivElement, Props>((props, ref): React.JSX.Element => {
  const { handleAxis, ...restProps } = props;
  return (
    <div
      ref={ref}
      {...restProps}
      className={classNames(`handle-${handleAxis}`, styles.Handle, props.className)}
    >
      <Icon
        component={ResizeIcon}
        className={styles.Icon}
      />
    </div>
  );
});

export default ResizeHandle;
