// import './canvas.css';
import React, { useRef, useMemo, useCallback, useEffect, useState, forwardRef, createElement } from 'react';
import { Position } from '@kemu-io/kemu-core/types';
import { useDrop } from 'react-dnd';
import { useContextMenu } from 'react-contexify';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import InfiniteViewer, { OnScroll } from 'react-infinite-viewer';
import { useDebouncedCallback } from 'use-debounce';
import { DEFAULT_THING_ID } from '@kemu-io/kemu-core/common/constants';
import {
	editCustomWidgetState,
	getBlockCanvasInfo,
	getCanvasInfo,
	getCustomWidgetState,
	setBlockCanvasInfo,
} from '../../app/recipe/utils.ts';
import { SelectedBlockInfo } from '../../types/core_t';
import {
	CANVAS_DRAGGABLE_CLASS,
	DroppableTypes,
	LM_CANVAS_CONTAINER_CLASS,
	CANVAS_CONTAINER_CLASS,
	LOGIC_MAPPER_CANVAS_CLASS,
	RIGHT_BUTTON_INDEX,
	ABORT_DRAGGING_CLASS,
	GET_CANVAS_DRAG_BUTTON_INDEX,
	CANVAS_NO_GRID_CLASS,
	CANVAS_SELECTION_START,
	CANVAS_WIDGET_CLASS,
	CANVAS_VIEWPORT_CLASS,
	CANVAS_VIEWER_CLASS
} from '../../common/constants.ts';
import { MENU_LOGIC_MAKER } from '../../features/LogicMapper/contextMenu/contextMenu';
import { selectVisibleGroup } from '../../features/Workspace/workspaceSlice';
import { DropEventResult } from '../../types/ui';
import styles from './canvas.module.css';
import { setCanvasZoom } from '@src/features/LogicMapper/logicMapperSlice.ts';

interface Props {
	children?: React.ReactNode;
	recipeId: string;
	/** 
	 * indicates if there is a block selected (normally this means someone double clicked on the block to access
	 * its Logic Maker
	*/
	selectedBlock: SelectedBlockInfo | null;
	loadingArea: 'workspace' | 'logic-mapper';
	onZoomChange?: (zoom: number) => void;
}

const Canvas = (props: Props, ref: React.Ref<HTMLDivElement>): React.JSX.Element => {
	const { onZoomChange, recipeId } = props;
	const containerRef = useRef<HTMLDivElement | null>(null);
	const currentFolder = useSelector(selectVisibleGroup);
	/** used as a workaround to prevent the library from triggering events after setting the position and zoom */
	const skipScrollEventRef = useRef<boolean>(false);
	const [defaultPosition, setDefaultPosition] = useState<{x: number, y: number, zoom: number}>();
	const { show: showMenu } = useContextMenu({ id: MENU_LOGIC_MAKER });
	const bgPatternRef = useRef<SVGPatternElement | null>(null);
	const viewerContainerRef = useRef<InfiniteViewer | null>(null);
	const dispatch = useDispatch();

	/* const canvasBackgroundEl = useMemo(() => {
		return containerRef.current?.querySelector(`.${CANVAS_DRAGGABLE_CLASS}.${LOGIC_MAPPER_CANVAS_CLASS}`);
	}
	
	// IMPORTANT: We DO need to monitor the ref to wait for the container to be mounted
	// eslint-disable-next-line react-hooks/exhaustive-deps
	, [containerRef.current]); */

	// Handles drag and Dropping of annotations and widgets
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [{ canDrop, isOver }, dropRef] = useDrop(() => ({
    accept: [DroppableTypes.Widget, DroppableTypes.CustomWidget, DroppableTypes.Annotation],
    drop: (item, monitor) => {
			const offset = monitor.getSourceClientOffset();
			// if (offset && containerRef.current && defaultPosition) {
			if (offset && containerRef.current && defaultPosition) {
				const dropTargetXy = containerRef.current.getBoundingClientRect();
				const result: DropEventResult = {
					// x: (offset.x - dropTargetXy.left + (defaultPosition.x)) * defaultPosition.zoom,
					// y: (offset.y - dropTargetXy.top + (defaultPosition.y)) * defaultPosition.zoom,
					x: defaultPosition.x + (offset.x  / defaultPosition.zoom) - (dropTargetXy.left / defaultPosition.zoom),
					y: defaultPosition.y + (offset.y / defaultPosition.zoom) - (dropTargetXy.top / defaultPosition.zoom),
					target: containerRef.current,
				};

				// console.log(`Offset: x: ${offset.x}, y: ${offset.y}; Target: x: ${dropTargetXy.left}, y: ${dropTargetXy.top}; Default: x: ${defaultPosition.x}, y: ${defaultPosition.y}; Result: x: ${result.x}, y: ${result.y}`);
				// const result: DropEventResult = {
				// 	x: offset.x ,
				// 	y: offset.y,
				// 	target: ref,,
				// };

				return result;
			}
		},
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  }), [defaultPosition]);

	// Store canvas position once scroll has stopped
	const onDragStopped = useDebouncedCallback((x: number, y: number, zoom: number) => {
		const newPos = { x, y };

		if (currentFolder?.groupId) {
			const widgetGroupState = getCustomWidgetState(recipeId, DEFAULT_THING_ID, currentFolder.groupId);
			const deltaMovement = (x - widgetGroupState.canvasPosition.x) + (y - widgetGroupState.canvasPosition.y);
			// Ignore clicks on the same position
			if (deltaMovement) {
				editCustomWidgetState(recipeId, DEFAULT_THING_ID, currentFolder.groupId, {
					...widgetGroupState,
					canvasPosition: newPos,
					canvasZoom: zoom,
				});
			}
		} else {
			// Logic Maker dragging
			const canvasInfo = getBlockCanvasInfo(recipeId, DEFAULT_THING_ID);

			canvasInfo.logicMapper = {
				...canvasInfo.logicMapper,
				position: newPos,
				zoom,
			};

			setBlockCanvasInfo(recipeId, DEFAULT_THING_ID, canvasInfo);
		}

		// console.log('onDragStopped', newPos);
		dispatch(setCanvasZoom(zoom));

		setDefaultPosition({
			...newPos,
			zoom,
		});
	}, 200);

	// NOTE: This should be done via redux actions?.. Maybe not for better performance? (unless undo is required)
	/* const handleDragStopped: DraggableEventHandler = useCallback((e, data) => {
		const newPos = {
			x: data.x,
			y: data.y
		};

		if (props.loadingArea === 'logic-mapper' && props.selectedBlock) {
			// Check if we are inside of a group
			if (currentFolder?.groupId) {
				const widgetGroupState = getCustomWidgetState(recipeId, props.selectedBlock.recipeId, currentFolder.groupId);
				const deltaMovement = (newPos.x - widgetGroupState.canvasPosition.x) + (newPos.y - widgetGroupState.canvasPosition.y);
				// Ignore clicks on the same position
				if (deltaMovement) {
					editCustomWidgetState(recipeId, props.selectedBlock.recipeId, currentFolder.groupId, {
						...widgetGroupState,
						canvasPosition: newPos
					});
				}
			} else {
				// Logic Maker dragging
				const canvasInfo = getBlockCanvasInfo(recipeId, props.selectedBlock.recipeId);

				canvasInfo.logicMaker = {
					...canvasInfo.logicMaker,
					position: newPos
				};

				setBlockCanvasInfo(recipeId, props.selectedBlock.recipeId, canvasInfo);
			}
		} else if (props.loadingArea === 'workspace') {
			// Workspace dragging
			const canvasInfo = getCanvasInfo(recipeId);
			canvasInfo.workspace = {
				...canvasInfo.workspace,
				position: newPos
			};

			setCanvasInfo(recipeId, canvasInfo);
		}

		// we are using the 'position' argument of <Draggable /> to reset its internal state, so, for the drag to preserve
		// the final position we need to set it here as it will get passed to the `position` attribute on the next render
		setDefaultPosition(newPos);
	}, [recipeId, props.selectedBlock, currentFolder?.groupId, props.loadingArea]); */

	/**
	 * Sets the canvas position and zoom without affecting redux or the recipe.
	 */
	const setCanvasPosAndZoom = useCallback((x: number, y: number, zoom: number) => {
		// Update jsPlumb zoom
		onZoomChange?.(zoom);

		viewerContainerRef.current?.setTo({ x, y, zoom });

		// Move the bg pattern
		if (bgPatternRef.current) {
			bgPatternRef.current.setAttribute('x', String((x * zoom * -1)));
			bgPatternRef.current.setAttribute('y', String((y * zoom * -1)));
		}
	}, [onZoomChange]);


	const debouncedSetCanvasPosAndZoom = useDebouncedCallback((x: number, y: number, zoom: number) => {
		setCanvasPosAndZoom(x, y, zoom);
	}, 200);


	const handleCanvasScroll = useCallback((e: OnScroll) => {
		// Fixes a bug in the library that causes offsets to be NaN
		/* eslint-disable @typescript-eslint/no-explicit-any */
		if (Number.isNaN((viewerContainerRef.current as any)?.infiniteViewer?.offsetX)) {
			(viewerContainerRef.current as any).infiniteViewer.offsetX = 0;
		}
		if (Number.isNaN((viewerContainerRef.current as any)?.infiniteViewer?.offsetY)) {
			(viewerContainerRef.current as any).infiniteViewer.offsetY = 0;
		}
		/* eslint-enable @typescript-eslint/no-explicit-any */

		if (skipScrollEventRef.current) {
			skipScrollEventRef.current = false;
			// return false to abort propagation
			return false;
		}

		/* IMPORTANT: using useDebouncedCallback because `setTo` may trigger
		 * onScroll events, causing a maximum call stack error.
		 */
		debouncedSetCanvasPosAndZoom(e.scrollLeft, e.scrollTop, e.zoomX);

		onDragStopped(e.scrollLeft, e.scrollTop, e.zoomX);

		return false;
	}, [onDragStopped, viewerContainerRef, debouncedSetCanvasPosAndZoom]);


	// If the selected block changes (enter or exit the logic maker)
	// we get the canvas position from the recipe
	useEffect(() => {
		let nextPosition: Position = { x: 0, y: 0 };
		let nextZoom = 1;
		if (props.loadingArea === 'workspace') {
			// Workspace
			const canvasInfo = getCanvasInfo(recipeId);
			nextPosition = canvasInfo.workspace.position;
		} else if (props.selectedBlock) {
			// Logic mapper root or widget
			if (currentFolder?.groupId) {
				const widgetGroupState = getCustomWidgetState(recipeId, props.selectedBlock.recipeId, currentFolder.groupId);
				nextPosition = { ...widgetGroupState.canvasPosition };
				nextZoom = widgetGroupState.canvasZoom || 1;
			} else {
				const blockCanvasInfo = getBlockCanvasInfo(recipeId, props.selectedBlock.recipeId);
				nextPosition = blockCanvasInfo.logicMapper?.position || { x: 0, y: 0 };
				nextZoom = blockCanvasInfo.logicMapper?.zoom || 1;
			}
		}

		setDefaultPosition({
			...nextPosition,
			zoom: nextZoom,
		});

		// IMPORTANT: calling setCanvasPosAndZoom here will trigger the onScroll event, setting
		// this to true will skip the next event.
		skipScrollEventRef.current = true;

		// IMPORTANT: We need to wait for the next frame to set the position and zoom
		// or the library will throw an error
		requestAnimationFrame(() => {
			setCanvasPosAndZoom(nextPosition.x, nextPosition.y, nextZoom);
		});

	}, [props.selectedBlock, recipeId, currentFolder?.groupId, props.loadingArea, setCanvasPosAndZoom]);


	const handleItemClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
		const classList = (event.target as HTMLDivElement)?.classList || [];
		// const clickedOnBackground = classList.contains(CANVAS_DRAGGABLE_CLASS);
		// const clickedOnCurrentArea = classList.contains(LOGIC_MAPPER_CANVAS_CLASS);
		const canvasBgClick = classList.contains(CANVAS_SELECTION_START);

		if (props.selectedBlock && canvasBgClick /* clickedOnBackground && clickedOnCurrentArea */) {
			event.preventDefault();
			showMenu(event, {
				props: {
					boundaryClass: props.loadingArea === 'workspace'
					? CANVAS_CONTAINER_CLASS
					: LM_CANVAS_CONTAINER_CLASS
				},
			});
		} else if (event.buttons === RIGHT_BUTTON_INDEX) {
			event.preventDefault();
		}
	};

	// const onDragStart = useCallback((event: DraggableEvent & { buttons?: number }) => {
	// 	// Allow dragging via middle button
	// 	if (event.buttons !== GET_CANVAS_DRAG_BUTTON_INDEX()) {
	// 		return false;
	// 	}
	// }, []);

	// TODO: Make this a developer setting toggle
	// const hideGrid = localStorage.getItem('no-kemu-grid') === 'true';

	useEffect(() => {
		return () => {
			onDragStopped.cancel();
		};
	}, [onDragStopped]);


	useEffect(() => {
		let viewerContainer: HTMLElement;

		const handleWheel = (e: WheelEvent) => {
			const target = e.target as HTMLElement;
			const isWidget = target.classList.contains(CANVAS_WIDGET_CLASS);
			// Allows zooming when the Ctrl key is pressed
			if (!isWidget && !e.ctrlKey) {
				// Prevents scrolling the canvas when inside a widget
				const insideWidget = target.closest(`.${CANVAS_WIDGET_CLASS}`);
				if (insideWidget) {
					// Stop event from bubbling up to the canvas
					e.stopPropagation();
					// Updated: allow default scroll to work inside the widget
					// e.preventDefault();
				}
			}
		};

		if (viewerContainerRef.current) {
			// @ts-expect-error - InfiniteViewer does have a scrollAreaElement property
			const scrollAreaElement = viewerContainerRef.current.scrollAreaElement as HTMLElement;
			if (scrollAreaElement) {
				scrollAreaElement.classList.add(CANVAS_SELECTION_START);
			}

			// Prevent scrolling when on a widget
			viewerContainer = viewerContainerRef.current.getContainer();
			if (viewerContainer) {
				viewerContainer.addEventListener('wheel', handleWheel, { passive: false, capture: true });
			}
		}

		return () => {
			if (viewerContainer) {
				viewerContainer.removeEventListener('wheel', handleWheel);
			}
		};
	}, []);

	return (
		/* 
		* `nodeRef={containerRef}` is a workaround to the ReactDOM.findDOMNode() warning. Check official documentation for more info
		* https://www.npmjs.com/package/react-draggable#draggable-api
		*/
		<div ref={dropRef} style={{ width: '100%', height: '100%' }}>
			{/* Background dots */}
			<svg className={styles.BgPattern}>
				<pattern id="pattern-1" ref={bgPatternRef} x="0" y="0" width="25" height="25" patternUnits="userSpaceOnUse">
					<circle cx="0.5369318181818182" cy="0.5369318181818182" r="0.5369318181818182" className="dots"></circle>
				</pattern>
				<rect x="0" y="0" width="100%" height="100%" fill="url(#pattern-1)"></rect>
			</svg>
			<div
				ref={containerRef}
				onContextMenu={handleItemClick}
				className={classNames(styles.CanvasContainer, LM_CANVAS_CONTAINER_CLASS,
					// props.loadingArea === 'workspace'
					// ? CANVAS_CONTAINER_CLASS
					// : LM_CANVAS_CONTAINER_CLASS
				)}
			>
				{/* Wait for container to be set to avoid triggering warning of `findDOMNode` */}
				{/* {containerRef.current && (
					// <Draggable
					// 	nodeRef={containerRef}
					// 	cancel={`.canvas-block, .canvas-gate, .${ABORT_DRAGGING_CLASS}`}
					// 	handle={`.${CANVAS_DRAGGABLE_CLASS}`}
					// 	onStop={handleDragStopped}
					// 	onStart={onDragStart}
					// 	allowAnyClick={true}
					// 	position={defaultPosition}
					// 	bounds={{
					// 		left: -(canvasBackgroundEl ? ((canvasBackgroundEl?.clientWidth || 0) - (containerRef.current?.clientWidth || 0)) : containerRef.current?.clientWidth || 0),
					// 		top: -(canvasBackgroundEl ? ((canvasBackgroundEl?.clientHeight || 0) - (containerRef.current?.clientHeight || 0)) : containerRef.current?.clientHeight || 0),
					// 		right: 0,
					// 		bottom: 0,
					// 	}}
					// > */}
					<InfiniteViewer
						className={classNames(styles.Viewer, CANVAS_VIEWER_CLASS, LOGIC_MAPPER_CANVAS_CLASS)}
						ref={viewerContainerRef}
						useMouseDrag={true}
						onDragStart={(e) => {
							// Allow mouse dragging with middle button
							if (e.inputEvent.buttons !== 4) {
								return false;
							}
						}}
						useWheelScroll={true}
						useWheelPinch={true}

						useAutoZoom={true}
						displayHorizontalScroll={false}
						displayVerticalScroll={false}

						zoomRange={[0.2, 5]}
						rangeX={[-8000, 4000]}
						rangeY={[-8000, 4000]}
						preventWheelClick={false}

						onScroll={handleCanvasScroll}
					>
						<div
							ref={ref}
							className={classNames(
								styles.Viewport,
								CANVAS_SELECTION_START,
								CANVAS_VIEWPORT_CLASS,
								// 'viewport-maybe',
								// CANVAS_DRAGGABLE_CLASS,
								// {
								// 	[LOGIC_MAPPER_CANVAS_CLASS]: props.loadingArea === 'logic-mapper',
								// 	// [CANVAS_NO_GRID_CLASS]: hideGrid,
								// }
							)}
						>
							{ props.children }
						</div>
					</InfiniteViewer>

					{/* // </Draggable>
				)} */}
			</div>

		</div>
	);
};

export default forwardRef<HTMLDivElement, Props>(Canvas);
