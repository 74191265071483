import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';
import { Recipe, CachedRecipeDbInfo } from '@kemu-io/kemu-core/types';
import { RecipeType } from '@kemu-io/kemu-types';
import { NavigateFunction } from 'react-router-dom';
import { openRecipeAction } from './openRecipeReducer';
import { WorkspaceState } from '@src/features/Workspace/workspaceSlice';
import { showGlobalNotification } from '@src/features/interface/interfaceSlice';
import { getGlobalIntl } from '@src/assets/i18n/globalIntl';
import { AsyncRequestStatus } from '@src/types/core_t';
import routes from '@common/routes';
import { safeJsonParse } from '@common/utils';

type ImportContentsOptions = {
  navigate: NavigateFunction,
  contents: string | ArrayBuffer,
}

export const importRecipeFromContentsAction = createAsyncThunk(
  '/workspace/importRecipeFromContents',
  async (options: ImportContentsOptions, thunkAPI) => {
    const t = getGlobalIntl();

    try {
      // Convert ArrayBuffer to string if needed
      const contentsStr = options.contents instanceof ArrayBuffer
        ? new TextDecoder().decode(options.contents)
        : options.contents;

      // Parse recipe
      const recipe = safeJsonParse<Recipe>(contentsStr);
      if (!recipe) {
        throw new Error(t('Recipe.Import.Error.InvalidFormat', 'Invalid recipe format'));
      }

      // Create db info for the recipe
      const dbInfo: CachedRecipeDbInfo = {
        id: recipe.id,
        version: 1,
        authorId: '',
        recipeType: RecipeType.Desktop
      };

      // Open the recipe using the existing openRecipeAction
      await thunkAPI.dispatch(openRecipeAction({
        recipe,
        recipeName: recipe.name,
        dbInfo,
        isDefault: false
      }));

      options.navigate(routes.recipe.getDesktopRecipeRoute(recipe.id));

      thunkAPI.dispatch(showGlobalNotification({
        title: t('Recipe.Import.Success.Title'),
        message: t('Recipe.Import.Success.Message'),
        type: 'success',
      }));

      return recipe;

    } catch (error) {
      thunkAPI.dispatch(showGlobalNotification({
        title: t('Recipe.Import.Error.Title'),
        message: error.message || t('Recipe.Import.Error.Message'),
        type: 'error',
      }));
      throw error;
    }
  }
);

export const importRecipeFromContentsReducer = (builder: ActionReducerMapBuilder<WorkspaceState>): void => {
  builder
    .addCase(importRecipeFromContentsAction.pending, (state) => {
      state.currentRecipe.blocks.status = AsyncRequestStatus.loading;
    })
    .addCase(importRecipeFromContentsAction.rejected, (state, action) => {
      console.error('Error importing recipe:', action.error);
      state.currentRecipe.blocks.status = AsyncRequestStatus.error;
      state.currentRecipe.blocks.error = action.error;
    });
};
