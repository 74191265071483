import React, { useCallback, useState } from 'react';
import { Radio, Space } from 'antd';
import { CloudUploadOutlined, SaveOutlined } from '@ant-design/icons';
import styles from './SaveRecipeTypeModal.module.css';
import RoundedModal from '@components/roundedModal/roundedModal';
import useTranslation from '@hooks/useTranslation';

type Props = {
	visible: boolean;
	onClose: () => void;
	onSaveToCloud: () => void;
	onSaveToFile: () => void;
}

type SaveOption = 'cloud' | 'local';

const SaveRecipeTypeModal = ({ visible, onClose, onSaveToCloud, onSaveToFile }: Props) => {
	const t = useTranslation('Recipe.SaveModal');
	const cw = useTranslation('CommonWords');
	const [selectedOption, setSelectedOption] = useState<SaveOption>('cloud');
	const [saving, setSaving] = useState(false);

	const handleSave = useCallback(async () => {
		setSaving(true);
		try {
			if (selectedOption === 'cloud') {
				await onSaveToCloud();
			} else {
				await onSaveToFile();
			}
			onClose();
		} finally {
			setSaving(false);
		}
	}, [selectedOption, onSaveToCloud, onSaveToFile, onClose]);

	return (
		<RoundedModal
			title={t('Title')}
			visible={visible}
			onCancel={onClose}
			width={500}
			closeOnMaskClick={false}
			closable={true}
			loadingOkBtn={saving}
			okBtnLabel={cw('Save')}
			cancelBtnLabel={cw('Cancel')}
			onOk={handleSave}
		>
			<div className={styles.Content}>
				<Radio.Group
					value={selectedOption}
					onChange={e => setSelectedOption(e.target.value)}
					className={styles.OptionsGroup}
				>
					<Space direction="vertical" size="large" style={{ width: '100%', marginBottom: 20 }}>
						<Radio value="cloud" className={styles.Option}>
							<Space>
								<CloudUploadOutlined className={styles.OptionIcon} />
								<div>
									<div className={styles.OptionTitle}>{t('Cloud.Title', 'Save to Cloud')}</div>
									<div className={styles.OptionDescription}>
										{t('Cloud.Description', 'Save your recipe to the Kemu cloud for easy access across devices.')}
									</div>
								</div>
							</Space>
						</Radio>

						<Radio value="local" className={styles.Option}>
							<Space>
								<SaveOutlined className={styles.OptionIcon} />
								<div>
									<div className={styles.OptionTitle}>{t('Local.Title', 'Save to Local File')}</div>
									<div className={styles.OptionDescription}>
										{t('Local.Description', 'Download and save your recipe as a local file on your device.')}
									</div>
								</div>
							</Space>
						</Radio>
					</Space>
				</Radio.Group>
			</div>
		</RoundedModal>
	);
};

export default SaveRecipeTypeModal;
