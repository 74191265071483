let currentHandle: FileSystemFileHandle | null = null;

export const setFileHandle = (handle: FileSystemFileHandle): void => {
  currentHandle = handle;
};

export const getFileHandle = (): FileSystemFileHandle | null => {
  return currentHandle;
};

export const clearFileHandle = (): void => {
  currentHandle = null;
};
