import globals from '../globals';
import { getOrigin } from '../utils';
import { getDirectActionRoute } from './common';

const origin = getOrigin();

/** 
 * Loads a recipe directly
 **/
const getDirectRecipeRoute = (id?: string): string => `/view/recipe/${id || ''}`;
const getRecipeIdFromPath = (path: string): string => path.split(getDirectRecipeRoute()).pop() || '';
const getStaticAssetsRoute = (thingType: string, version: string): string => {
	// Force dev environment resolution in local environment
	// const domain = globals.STAGE === 'local' || globals.STAGE === 'development' ? 'dev.kemu.io' : globals.KEMU_APP_DOMAIN;
	return `${globals.THING_STATIC_ASSETS}/${thingType}/v${version}`;
};

/** creates a public url that users can use to import recipes */
const getPublicRecipeImportUrl = (id: string): string => `${origin}${getDirectActionRoute()}/ri/${id !== undefined ? id : ':id?'}`;
const getImportRecipeDirectRoute = (id?: string): string => `${getDirectActionRoute()}/ri/${id !== undefined ? id : ':id?'}`;
const getImportRecipeRisks = (): string => `https://support.kemu.io/recipe/import-risks`;

/** loads the given recipe id in the workspace */
const getCloudRecipeRoute = (id?: string): string => `/mode/cloud/${id !== undefined ? id : ':id?'}`;
const getBrowserRecipeRoute = (id?: string): string => `/mode/browser/${id !== undefined ? id : ':id?'}`;
const getDesktopRecipeRoute = (id?: string): string => `/mode/desktop/${id !== undefined ? id : ':id?'}`;
const getLoadRecipeFileContentsFromCacheRoute = (cacheId?: string): string => `/mode/file/${cacheId !== undefined ? cacheId : ':cacheId?'}`;

export default {
	getDirectRecipeRoute,
	getRecipeIdFromPath,
	getStaticAssetsRoute,
	getImportRecipeDirectRoute,
	getImportRecipeRisks,
	getPublicRecipeImportUrl,
	getBrowserRecipeRoute,
	getCloudRecipeRoute,
	getDesktopRecipeRoute,
	getLoadRecipeFileContentsFromCacheRoute,
};
