import React, { useCallback, useEffect, useState } from 'react';
import Card from 'antd/lib/card';
import Rate from 'antd/lib/rate';
import Badge from 'antd/lib/badge';
import Carousel from 'antd/lib/carousel';
import Divider from 'antd/lib/divider';
import Tooltip from 'antd/lib/tooltip';
import Skeleton from 'antd/lib/skeleton';
import SafetyCertificateOutlined from '@ant-design/icons/SafetyCertificateOutlined';
import theme from 'antd/lib/theme';
import classNames from 'classnames';
import Image from 'antd/lib/image';
import { MarketplaceRecordWithRatings } from '@kemu-io/kemu-types';
import HubServiceInstallButton from '../HubServiceInstallButton/HubServiceInstallButton';
import styles from './HubServiceDetailsPage.module.css';
import SvgFromContent from '@components/SvgFromContent/SvgFromContent';
import useTranslation from '@hooks/useTranslation';
import { HubServiceDetails } from '@src/types/hubService';
import MarkdownViewer from '@components/MarkdownViewer/MarkdownViewer';
import { ratePublication } from '@src/api/marketplace/marketplaceApi';
import useInstallMarketplaceItem from '@hooks/useInstallMarketplaceItem';
import useKemuHubLink from '@hooks/useHubLink';
import useIsServiceInstalled from '@hooks/useIsServiceInstalled';
import globals from '@common/globals';
type Props = {
  // hubServiceId: string;
  serviceInfo: MarketplaceRecordWithRatings;
  onGoBack: () => void;
}

const marketplacePublicationUrl = globals.MARKETPLACE_PUBLIC_URL;
const getFullAssetPath = (assetPath: string) => {
  return `${marketplacePublicationUrl}/${assetPath}`;
};

const HubServiceDetailsPage = (props: Props) => {
  const { serviceInfo } = props;
  const { token: { colorPrimary } } = theme.useToken();
  const { online } = useKemuHubLink();
  const [serviceDetails, setServiceDetails] = useState<HubServiceDetails | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const t = useTranslation('Marketplace.ServiceDetailPage');
  const [submitRating, setSubmitRating] = useState(false);
  const isServiceInstalled = useIsServiceInstalled();
  const {
    installedWithSameVersion,
    installedWithLowerVersion,
    installedWithMissingSecrets,
  } = isServiceInstalled(serviceInfo.name, serviceInfo.version);
  const alreadyInstalled = installedWithSameVersion || installedWithMissingSecrets || installedWithLowerVersion;
  const { installHubService, installingService } = useInstallMarketplaceItem();
  const cw = t.withBaseKey('CommonWords');

  const fetchMarkdownForRecord = useCallback(async (record: MarketplaceRecordWithRatings) => {
    try {
      setIsLoading(true);
      let contents: string | null = null;
      if (record.docs.url) {
        const docUrl = `${marketplacePublicationUrl}/${record.docs.url}/README.md`;
        const response = await fetch(docUrl);
        contents = await response.text();
      } else {
        contents = record.docs.contentMd || null;
      }

      setServiceDetails({
        ...record,
        docMarkdown: contents || '',
      });

    } catch (error) {
      console.error('Error fetching markdown from URL', error);
      return null;
    } finally {
      setIsLoading(false);
    }
  }, []);

  const handleRatingChange = async (value: number) => {
    console.log('Rating changed to', value);
    setSubmitRating(true);
    const response = await ratePublication({
      publicationId: (serviceInfo as any).objectId || serviceInfo.id,
      rating: value,
    });

    if (serviceDetails) {
      setServiceDetails({
        ...serviceDetails,
        rating: {
          average: response.stats.average,
          total: response.stats.total,
        },
      });
    }

    console.log('Rating response', response);
    setSubmitRating(false);
  };


  const handleInstallService = () => {
    if (alreadyInstalled) {
      return;
    }

    installHubService({
      marketplaceItemId: serviceInfo.id,
      title: serviceInfo.title,
    });
  };

  useEffect(() => {
    fetchMarkdownForRecord(serviceInfo);
  }, [serviceInfo, fetchMarkdownForRecord]);

  return (
    <div className={styles.DetailsPageContainer}>
      {/* <div className={styles.Navigation}>
        <Button
          type="default"
          onClick={props.onGoBack}
          icon={<ArrowLeftOutlined />}
        >
          {cw('Back')}
        </Button>
      </div> */}
      <div className={styles.PageWrapper}>
        <div className={styles.PageBody}>
          {isLoading ? (
            <LoadingState />
          ) : serviceDetails && (
            <Card>
              {/* Header */}
              <div className={styles.Header}>
                <div className={styles.IconWrapper}>
                  <SvgFromContent content={serviceDetails.iconSvg.trim()} height={95} width={95} />
                </div>

                <div className={styles.TitleSection}>
                  <div className={styles.Title}>{serviceDetails.title}</div>
                  <div className={styles.Author}>
                    {serviceDetails.isKemuOfficial && (
                      <Tooltip title={`${cw('KemuOfficial')} ${cw('Account')}`}>
                        <Badge
                          count={<SafetyCertificateOutlined style={{ color: colorPrimary }} />}
                        />
                      </Tooltip>
                    )}
                    {serviceDetails.author.name}
                  </div>
                  <div className={styles.Rating}>
                    <Rate value={serviceDetails.rating.average} onChange={handleRatingChange} allowHalf disabled={submitRating} />
                    <span className={styles.ReviewCount}>({serviceDetails.rating.total})</span>
                  </div>
                </div>

                <div className={styles.InstallBtnContainer}>
                  <HubServiceInstallButton
                    serviceName={serviceInfo.name}
                    serviceVersion={serviceInfo.version}
                    installingService={installingService}
                    handleInstallService={handleInstallService}
                  />
                </div>
              </div>


              {/* Image Previews */}
              {(serviceDetails.previewImages?.length || 0) > 0 && (
                <>
                  <Divider />
                  <div className={styles.PreviewSection}>
                    <Carousel autoplay arrows>
                      {serviceDetails.previewImages?.map((image, index) => (
                        <div key={index}>
                          <div className={styles.PreviewImageContainer}>
                            <Image
                              src={getFullAssetPath(image)}
                              alt={`Preview ${index + 1}`}
                              className={styles.PreviewImage}
                            />
                          </div>
                        </div>
                      ))}
                    </Carousel>
                  </div>
                </>
              )}

              <Divider />

              {/* Service Description */}
              <div className={styles.Description}>
                <h3>{t('AboutThisService')}</h3>
                {serviceDetails.docMarkdown ? (
                  <MarkdownViewer
                    markdown={serviceDetails.docMarkdown}
                    docsUrl={serviceInfo.docs.url || ''}
                  />
                ) : (
                  <p>
                    {serviceDetails.description || 'No description available.'}
                  </p>
                )}
              </div>


              {/* Ratings Section */}
              {/* 
              <Divider />
              <div className={styles.RatingsSection}>
                <h3>{t('RatingsAndReviews')}</h3>
              </div> */}

              <Divider />

              {/* Change Log */}
              {serviceDetails.changeLog && (
                <div className={styles.ChangeLog}>
                  <h3>{t('WhatIsNew')}</h3>
                  <p>
                    {serviceDetails.changeLog}
                  </p>
                </div>
              )}
            </Card>
          )}
        </div>
      </div>
    </div>
  );
};


const LoadingState = () => {
  return (
    <Card>
      {/* Header skeleton */}
      <div className={styles.Header}>
        <div className={classNames(styles.IconWrapper, styles.NoBorder)}>
          <Skeleton.Image active className={styles.IconSkeleton} />
        </div>

        <div className={styles.TitleSection}>
          <Skeleton.Input active size="large" block style={{ width: '60%' }} />
          <Skeleton.Input active size="small" block style={{ width: '30%', marginTop: 8 }} />
          <div style={{ marginTop: 8 }}>
            <Skeleton.Input active size="small" block style={{ width: '40%' }} />
          </div>
        </div>

        <div className={styles.InstallBtnContainer}>
          <Skeleton.Button active size="default" style={{ width: 100 }} />
        </div>
      </div>

      <Divider />

      {/* Preview Images skeleton */}
      <div className={styles.PreviewSection}>
        <div className={styles.PreviewImageContainer}>
          <Skeleton.Image active className={styles.PreviewSkeleton} />
        </div>
      </div>

      <Divider />

      {/* Description skeleton */}
      <div className={styles.Description}>
        <Skeleton active paragraph={{ rows: 4 }} />
      </div>

      <Divider />

      {/* Ratings skeleton */}
      <div className={styles.RatingsSection}>
        <Skeleton active paragraph={{ rows: 3 }} />
      </div>

      <Divider />

      {/* Changelog skeleton */}
      <div className={styles.ChangeLog}>
        <Skeleton active paragraph={{ rows: 2 }} />
      </div>
    </Card>
  );
};


export default HubServiceDetailsPage;
