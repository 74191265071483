import React, { useEffect, useState } from 'react';
import { ColorResult, TwitterPicker } from 'react-color';
import classNames from 'classnames';
import styles from './colorSwatch.module.css';

interface Props {
	color?: string;
	className?: string;
	width?: string;
	/** default colors */
	colors?: string[];
	popupClassName?: string;
	showColorPicker?: boolean;
	onColorChange: (color: string) => void;
}

const ColorSwatch = (props: Props): React.JSX.Element => {
	const [showPicker, setShowPicker] = useState(false);

	const handleColorChange = (color: ColorResult, originalEvent?: React.ChangeEvent<HTMLInputElement>) => {
		// Prevent hiding the popup if users are typing a value
		if (originalEvent?.currentTarget && originalEvent?.currentTarget?.value?.length < 6) {
			return;
		}

		props.onColorChange?.(color.hex);
		setShowPicker(false);
	};


	const onTogglePopup = () => {
		setShowPicker(v => !v);
	};

	useEffect(() => {
		if (props.showColorPicker !== undefined) {
			setShowPicker(props.showColorPicker);
		}
	}, [props.showColorPicker]);

	return (
		<>
			<div
				className={classNames(styles.Swatch, props.className)}
				onClick={onTogglePopup}
				style={{ backgroundColor: props.color }}
			/>
			{showPicker && (
				<div className={classNames(styles.Popover, props.popupClassName)}>
					<TwitterPicker
						width={props.width}
						colors={props.colors}
						color={props.color || '#fff'}
						data-name="color"
						onChange={handleColorChange}
					/>
				</div>
			)}
		</>
	);
};

export default ColorSwatch;
