import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { DEFAULT_THING_ID, DEFAULT_THING_VERSION } from '@kemu-io/kemu-core/common/constants';
// import SplitLayout from '../../components/layout/SplitLayout/SplitLayout';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Layout } from 'antd';
import classNames from 'classnames';
import { currentRecipePoolId, selectOpeningRecipe } from '../Workspace/workspaceSlice';
import routes from '../../common/routes/index';
import { downloadRecipeAction } from '../interface/reducers/downloadRecipeReducer';
import useAlert from '../../components/alert/useAlert';
import styles from './BrowserRecipeScreen.module.css';
import LogicMapperSection from '@components/layout/HorizontalSplit/LogicMapperSection';
import { SelectedBlockInfo } from '@src/types/core_t';
import MainMenu from '@components/mainMenu/mainMenu';

// interface MenuState {
// 	collapsed: boolean;
// 	selectedMenu: string;
// }

// const CONFIG_NAME = 'mainMenu';
// const defaultMenuConfig: MenuState = {
// 	collapsed: true,
// 	selectedMenu: 'home'
// };

const BrowserRecipeScreen = (): React.JSX.Element | null => {
  const dispatch = useDispatch();
  // const recipeType = useSelector(selectCurrentRecipeType);
  const alert = useAlert();
  const navigate = useNavigate();
  const { id: recipeIdInPath } = useParams<{id?: string}>();
  // const baseRecipeLoaded = useSelector(selectBaseRecipeLoaded);
  // const [collapsed, setCollapsed] = useState(getStoredConfig<MenuState>(CONFIG_NAME, defaultMenuConfig).collapsed);
  const recipePoolId = useSelector(currentRecipePoolId);
  const downloadRecipeRef = useRef(false);
  // const workspaceState = useSelector(selectCurrentRecipe);
  const openingRecipe = useSelector(selectOpeningRecipe);

  const defaultThing: SelectedBlockInfo = useMemo(() => {
    return {
      DbId: DEFAULT_THING_ID,
      recipeId: DEFAULT_THING_ID,
      recipePoolId: recipePoolId || '',
      title: '',
      version: DEFAULT_THING_VERSION,
    };
  }, [recipePoolId]);

  useEffect(() => {
    // Download the recipe in the path if not already initialized
    // if(!baseRecipeLoaded) {
    //   navigate(routes.getBaseRoute());
    //   return;
    // }
    if (!recipePoolId && recipeIdInPath) {
      if (!downloadRecipeRef.current) { // Prevent downloading the recipe more than once
        downloadRecipeRef.current = true;
        // Download contents and initialized the recipe
        dispatch(downloadRecipeAction({
          recipeId: recipeIdInPath,
          alert,
          navigate
        }));
      }
    } else if (!recipePoolId && !recipeIdInPath) {
      // Redirect to environment selection
      navigate(routes.getBaseRoute());
    }
  }, [recipeIdInPath, alert, navigate, dispatch, recipePoolId]);

  // if (!recipeType) { return null; }

  return (
    // <>
		// 	<SplitLayout />
    // </>
    <DndProvider backend={HTML5Backend}>
      <div id="layout-container" className="light-theme">
        <Layout className={classNames('layout-section', styles.Layout)} >
          {/* <div className='lm-container' style={{
            display: 'flex',
            width: '100%'
          }}> */}
            <MainMenu collapsed={true} />
            {!openingRecipe && (
              <LogicMapperSection
                openedBlock={defaultThing}
                recipePoolId={recipePoolId}
                visible={true}
              />
            )}
          {/* </div> */}
        </Layout>
      </div>
    </DndProvider>
  );
};

export default BrowserRecipeScreen;
