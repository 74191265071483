import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Tooltip, Popover, Button } from 'antd';
import { SettingOutlined } from '@ant-design/icons';
import classNames from 'classnames';
import {
  disableBreakpoints,
  enableBreakpoints,
  disableEventTracking,
  enableEventTracking
} from '@kemu-io/kemu-core/processor';
import styles from './LogicMapperSettings.module.css';
import useTranslation from '@hooks/useTranslation';
import {
  LogicMapperSettings,
  selectLogicMapperSettings,
  setLogicMapperSettingValue
} from '@src/features/interface/interfaceSlice.ts';
import KemuSwitch from '@components/form-control/kemuSwitch/KemuSwitch';

// type SettingType = 'WidgetInfoPanel';

type Props = {
  hasItems?: boolean;
}

const SwitchRow = ({ label, settingKey, checked, onChange }: {
  label: string,
  settingKey: keyof LogicMapperSettings,
  checked: boolean,
  onChange: (checked: boolean, event: React.MouseEvent, settingKey: keyof LogicMapperSettings) => void
}) => {
  return (
    <div className={styles.Row}>
      <label>{label}</label>
      <KemuSwitch
        data-kemu-meta={`logic-mapper-setting::toggle-${settingKey}`}
        data={settingKey}
        size='default'
        checked={checked}
        onChange={onChange}
      />
    </div>
  );
};

const LogicMapperSettingsPanel = (props: Props) => {
  const { hasItems } = props;
  const t = useTranslation('LogicMapper.Settings');
  const userSettings = useSelector(selectLogicMapperSettings);
  const dispatch = useDispatch();
  // const [popOverVisible, setPopOverVisible] = useState(false);

  const toggleSetting = (checked: boolean, event: React.MouseEvent, settingKey: keyof LogicMapperSettings) => {
    if (settingKey) {
      dispatch(setLogicMapperSettingValue({
        key: settingKey,
        value: checked,
      }));
    }
  };

  useEffect(() => {
    if (userSettings.showDebuggingPanel) {
      enableBreakpoints();
      enableEventTracking();
    } else {
      disableBreakpoints();
      // disableEventTracking();
    }

    /* if (!userSettings.showWidgetProcessingAnimations && !userSettings.showDebuggingPanel) {
      disableEventTracking();
    } else {
      enableEventTracking();
    } */
  }, [userSettings.showDebuggingPanel]);

  // useEffect(() => {
  //   if (userSettings.showWidgetProcessingAnimations) {
  //     enableEventTracking();
  //   } else {
  //     disableEventTracking();
  //   }
  // }, [userSettings.showWidgetProcessingAnimations]);

  const content = (
    <div className={styles.ContentBody}>
      <SwitchRow
        label={t('PortDebugPanel')}
        settingKey="portInspectorVisible"
        checked={userSettings.portInspectorVisible}
        onChange={toggleSetting}
      />

      <SwitchRow
        label={t('ShowDebuggingPanel')}
        settingKey="showDebuggingPanel"
        checked={userSettings.showDebuggingPanel}
        onChange={toggleSetting}
      />

      <SwitchRow
        label={t('AnimateConnectionEvent')}
        settingKey="showConnectionAnimations"
        checked={userSettings.showConnectionAnimations}
        onChange={toggleSetting}
      />

      {/* 
      Update 12/Feb/2025: Disabling this setting since it doesn't make sense to have
      debugging without animations.
       */}
      {/* <SwitchRow
        label={t('AnimateWidgetProcessing')}
        settingKey="showWidgetProcessingAnimations"
        checked={userSettings.showWidgetProcessingAnimations}
        onChange={toggleSetting}
      /> */}

      <SwitchRow
        label={t('ShowSuccessTick')}
        settingKey="showSuccessTick"
        checked={userSettings.showSuccessTick}
        onChange={toggleSetting}
      />
    </div>
  );

  return (
    <div className={classNames(styles.ButtonWrapper, hasItems && styles.HasBreadcrumb)}>
      <Tooltip title={t('Tooltip', 'Settings')} mouseEnterDelay={0.5}>
        <Popover
          // onOpenChange={setPopOverVisible}
          content={content}
          title={<b>{t('Title', 'Settings')}</b>}
          trigger="click"
          placement='bottom'
        >
          <Button
            type="link"
            className={styles.Button}
            data-setting-type="settings"
            shape="circle"
            icon={<SettingOutlined />}
          />
        </Popover>
      </Tooltip>
    </div>
  );
};

export default LogicMapperSettingsPanel;
