import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { GetFileContentFromCacheIdArgs, GetFileContentFromCacheIdResponse, KemuHubFunctions } from '@kemu-io/hs-types';
import { useDebouncedCallback } from 'use-debounce';
import { useDispatch } from 'react-redux';
import LoadingEnvironmentScreen from '../LoadingEnvironmentScreen/LoadingEnvironmentScreen';
import routes from '@common/routes';
import useKemuHubLink from '@hooks/useHubLink';
import useTranslation from '@hooks/useTranslation';
import { importRecipeFromContentsAction } from '@src/app/reducers/workspace/importRecipeFromContentsReducer';


const LoadRecipeFileContentsFromDisk = (): React.JSX.Element | null => {
  const { cacheId } = useParams<{ cacheId?: string }>();
  const { connector, status, online } = useKemuHubLink();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const t = useTranslation('Recipe.Import');

  const rebootToInstall = useDebouncedCallback(() => {
    if (!cacheId) { return navigate(routes.getBaseRoute()); }
    const args: GetFileContentFromCacheIdArgs = [{
      cacheId,
    }];

    connector.executeHubFunction<GetFileContentFromCacheIdResponse>(KemuHubFunctions.GetFileContentFromCacheId, args).then((res) => {
      if (!res) { throw new Error(`Invalid response from hub`); }
      const [file] = res;
      dispatch(importRecipeFromContentsAction({
        contents: file.contents,
        navigate,
      }));

    }).catch(err => {
      console.error(`Failed to load recipe file contents from disk: ${err}`);
      navigate(routes.getBaseRoute());
    });
  }, 100);

  // Aborts loading after 2 seconds
  const navigateToBase = useDebouncedCallback(() => {
    console.log('Failed to communicate with hub, aborting recipe loading');
    navigate(routes.getBaseRoute());
  }, 4000);

  useEffect(() => {
    if (cacheId && status === 'acknowledged') {
      rebootToInstall();
      navigateToBase.cancel();
    } else if (status === 'disconnected') {
      navigateToBase();
    }
  }, [cacheId, rebootToInstall, status, navigateToBase]);

  return (
    <LoadingEnvironmentScreen
      title={t('Loading')}
    />
  );
};

export default LoadRecipeFileContentsFromDisk;
