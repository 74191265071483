import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';
import { Recipe, CachedRecipeDbInfo } from '@kemu-io/kemu-core/types';
import { RecipeType } from '@kemu-io/kemu-types';
import { NavigateFunction } from 'react-router-dom';
import { openRecipeAction } from './openRecipeReducer';
import { WorkspaceState } from '@src/features/Workspace/workspaceSlice';
import { showGlobalNotification } from '@src/features/interface/interfaceSlice';
import { getGlobalIntl } from '@src/assets/i18n/globalIntl';
import { AsyncRequestStatus } from '@src/types/core_t';
import routes from '@common/routes';
import { safeJsonParse } from '@common/utils';

type ImportFileOptions = {
  navigate: NavigateFunction,
  handle?: FileSystemFileHandle | null,
  saveHandle?: (handle: FileSystemFileHandle) => void,
}

export const importFileRecipeAction = createAsyncThunk('/workspace/importFileRecipe', async (options: ImportFileOptions, thunkAPI) => {
  const t = getGlobalIntl();
  let handle = options.handle;

  try {
    // Show file picker
    if (!handle) {
      // @ts-expect-error missing types
      const [fileHandle] = await window.showOpenFilePicker({
        types: [{
          description: 'Kemu Recipe',
          accept: {
            'application/json': ['.kemu']
          }
        }]
      });

      handle = fileHandle;
    }

    // Get the file
    const file = await (handle as FileSystemFileHandle).getFile();

    // Read file contents
    const contents = await file.text();

    // Parse recipe
    const recipe = safeJsonParse<Recipe>(contents);
    if (!recipe) {
      throw new Error('Invalid recipe file');
    }

    // Create db info for the recipe
    const dbInfo: CachedRecipeDbInfo = {
      id: recipe.id,
      version: 1,
      authorId: '',
      recipeType: RecipeType.Desktop
    };

    // Open the recipe using the existing openRecipeAction
    await thunkAPI.dispatch(openRecipeAction({
      recipe,
      recipeName: recipe.name,
      dbInfo,
      isDefault: false
    }));

    if (options.saveHandle) {
      options.saveHandle(handle as FileSystemFileHandle);
    }

    options.navigate(routes.recipe.getDesktopRecipeRoute(recipe.id));

    thunkAPI.dispatch(showGlobalNotification({
      title: t('Recipe.Import.Success.Title', 'Recipe Imported'),
      message: t('Recipe.Import.Success.Message', 'Recipe has been imported successfully'),
      type: 'success',
    }));

    return recipe;

  } catch (error) {
    // Don't show error if user simply cancelled the file picker
    if (error.name !== 'AbortError') {
      thunkAPI.dispatch(showGlobalNotification({
        title: t('Recipe.Import.Error.Title', 'Import Failed'),
        message: t('Recipe.Import.Error.Message', 'Failed to import recipe file'),
        type: 'error',
      }));
    }
    throw error;
  }
});

export const importFileRecipeReducer = (builder: ActionReducerMapBuilder<WorkspaceState>): void => {
  builder
    .addCase(importFileRecipeAction.pending, (state) => {
      state.currentRecipe.blocks.status = AsyncRequestStatus.loading;
    })
    .addCase(importFileRecipeAction.rejected, (state, action) => {
      console.error('Error importing recipe:', action.error);
      state.currentRecipe.blocks.status = AsyncRequestStatus.error;
      state.currentRecipe.blocks.error = action.error;
    });
};
