import executionTracker from '@kemu-io/kemu-core/common/executionTracker';
import { useMemo } from 'react';

/**
 * Returns a set of functions to interact with the core breakpoints
 */
const useCoreBreakpoints = () => {
  const result = useMemo(() => {
    return {
      /**
       * Returns all breakpoints, including disabled ones
       */
      getBreakpoints: executionTracker.getAllBreakpoints,
      /**
       * Sets a breakpoint for a widget
       */
      setBreakpoint: executionTracker.setBreakpoint,
      /**
       * Removes a breakpoint for a widget
       */
      removeBreakpoint: executionTracker.removeBreakpoint,
      /**
       * Returns whether a widget has a breakpoint set
       */
      hasBreakpoint: executionTracker.hasBreakpoint,
    };
  }, []);

  return result;
};

export default useCoreBreakpoints;
