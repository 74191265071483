import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Col, Row } from 'antd';
import { MarketplaceRecordWithRatings } from '@kemu-io/kemu-types';
import { ValidPlatformArch } from '@kemu-io/hs-types';
import { useNavigate, useLocation } from 'react-router-dom';
import Card from 'antd/es/card/Card';
import { Empty } from 'antd/lib';
import styles from './HubServicesScreen.module.css';
import HubServiceCard from './HubServiceCard/HubServiceCard';
import HubServiceDetailsPage from './HubServiceDetailsPage/HubServiceDetailsPage';
import HubServiceCardSkeleton from './HubServiceCardSkeleton/HubServiceCardSkeleton';
import useQueryString from '@common/hooks/useQueryString';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { SearchInputContextType } from '@src/contexts/SearchInputContext';
import useTranslation from '@hooks/useTranslation';
import useMarketplaceFetch from '@hooks/useMarketplaceFetch';
import useHubSystemInfo from '@hooks/useHubSystemInfo';


const GridResults = (props: {
	hits: MarketplaceRecordWithRatings[];
	hitRenderer: (hit: MarketplaceRecordWithRatings) => React.JSX.Element;
}) => {
	const { hits, hitRenderer } = props;

	return (
		<div style={{ maxWidth: '1500px', margin: '0 auto', height: '100%' }}>
			<Row
				gutter={[
					{ xs: 0, sm: 12, md: 16, lg: 16, xl: 24 },
					{ xs: 16, sm: 20, md: 24, lg: 24, xl: 32 }
				]}
				className={styles.KemuRow}
				justify={'start'}
			>
				{hits.map((hit) => (
					<Col key={hit.id} xs={24} sm={12} md={12} lg={8} xl={8} xxl={5} style={{ minWidth: '465px' }}>
						{hitRenderer(hit)}
					</Col>
				))}
			</Row>
		</div>
	);
};


type Props = {
	onSetSearchContext: (context: SearchInputContextType) => void;
};

const defaultPlatformArch: ValidPlatformArch = 'win-x64';
const resultsPerPage = 100;

const HubServicesScreen = ({ onSetSearchContext }: Props): React.JSX.Element | null => {
	const queryStr = useQueryString();
	const paramServiceId = queryStr.get('hs');
	// const selectedCard = queryStr.get('select');
	const fetchMarketplace = useMarketplaceFetch();
	const [results, setResults] = useState<MarketplaceRecordWithRatings[] | null>(null);
	// const [currentPage, setCurrentPage] = useState(0);
	const [selectedService, setSelectedService] = useState<MarketplaceRecordWithRatings | null>(null);
	const hubSystemInfo = useHubSystemInfo();
	const platformArch = hubSystemInfo?.platformArch || defaultPlatformArch;
	const containerRef = useRef<HTMLDivElement>(null);
	const [isLoading, setIsLoading] = useState(true);
	const fetchFrom = useRef(1);
	const finishedResults = useRef(false);
	const lastQuery = useRef('');

	// console.log(hubSystemInfo);
	const t = useTranslation('Marketplace.HubServices');

	const navigate = useNavigate();
	const location = useLocation();

	const updateSelectedService = useCallback((serviceId: string | null) => {
		const searchParams = new URLSearchParams(location.search);

		if (serviceId) {
			searchParams.set('hs', serviceId);
		} else {
			searchParams.delete('hs');
		}

		// Preserve other query parameters while updating 'service'
		navigate({
			pathname: location.pathname,
			search: searchParams.toString()
		}, {
			replace: false // false to add to browser history
		});
	}, [location, navigate]);

	const handleLoadDetails = useCallback((serviceInfo: MarketplaceRecordWithRatings) => {
		// Add selected service to the query string
		updateSelectedService(serviceInfo.id);
		// setSelectedService(serviceInfo);
	}, [updateSelectedService]);

	const fetchNextResults = useCallback(async (reset: boolean = false) => {
		if (finishedResults.current) {
			return;
		}

		setIsLoading(true);
		const results = await fetchMarketplace({
			query: lastQuery.current,
			from: fetchFrom.current,
			total: resultsPerPage,
			platform: platformArch,
		}).finally(() => {
			setIsLoading(false);
		});

		if (results.length < resultsPerPage) {
			finishedResults.current = true;
		}
		setResults((c) => [...(reset ? [] : c || []), ...results]);
	}, [fetchMarketplace, platformArch]);

	const handleGlobalSearch = useCallback(async (query: string) => {
		lastQuery.current = query;
		finishedResults.current = false;
		fetchFrom.current = 1;
		fetchNextResults(true);
	}, [fetchNextResults]);


	const handleGoBack = useCallback(() => {
		setSelectedService(null);
	}, []);

	/* function handleScroll() {
		const parent = containerRef.current?.parentElement;
		if (parent) {
			console.log('handleScroll', parent.scrollTop, parent.clientHeight, parent.scrollHeight);
			if (parent.scrollTop + parent.clientHeight !== parent.scrollHeight) {
				return;
			}

			if (isLoading) {
				return;	
			}

			console.log('Fetch more list items!');
			fetchFrom.current += 1;
			fetchNextResults();
		}
	} */

	// Register the search handler when the component mounts
	useEffect(() => {
		onSetSearchContext?.({
			onSearch: handleGlobalSearch,
		});
	}, [onSetSearchContext, handleGlobalSearch]);

	useEffect(() => {
		if (paramServiceId && !selectedService && results?.length) {
			const service = results.find((r) => r.id === paramServiceId);
			if (service) {
				// handleLoadDetails(service);
				setSelectedService(service);
			}
		} else if (!paramServiceId && selectedService) {
			setSelectedService(null);
		}
	}, [paramServiceId, handleLoadDetails, selectedService, results]);

	/* useEffect(() => {
		const parent = containerRef.current?.parentElement;
		if (parent) {
			parent.addEventListener('scroll', handleScroll);
		}

		return () => {
			if (parent) {
				parent.removeEventListener('scroll', handleScroll);
			}
		};
	}, []); */

	// if(paramServiceId && !selectedService) {
	// 	return (
	// 		<div>Loading details....</div>
	// 		// <div className={styles.HubServicesScreen}>
	// 		// 	<GridResults
	// 		// 		hits={Array(Math.min(resultsPerPage, 15)).fill('x')}
	// 		// 		hitRenderer={HubServiceCardSkeleton}
	// 		// 	/>
	// 		// </div>
	// 	)	
	// }

	if (selectedService) {
		return <HubServiceDetailsPage serviceInfo={selectedService} onGoBack={handleGoBack} />;
	}

	return (
		<div className={styles.HubServicesScreen} ref={containerRef}>
			{/* Todo load results based on scroll factor */}
			{/* {!!results?.documents.length && (
				<StyledPagination
					wrapperClassName={styles.PaginationWrapper}
					current={results.page}
					total={results.total}
					onChange={handlePageChange}
					pageSize={resultsPerPage}
					showSizeChanger={false}
				/>
			)} */}

			<GridResults
				hits={results || []}
				hitRenderer={
					(hit) => (
						<HubServiceCard
							serviceInfo={hit}
							onLoadDetails={handleLoadDetails}
						/>
					)
				}
			/>

			{isLoading && (
				<div className={styles.HubServicesScreen}>
					<GridResults
						hits={Array(Math.min(resultsPerPage, 15)).fill('x')}
						hitRenderer={HubServiceCardSkeleton}
					/>
				</div>
			)}

			{results && results.length === 0 && (
				<Card>
					<div className={styles.NoResults}>
						<Empty description={t('NoneFound')} />
					</div>
				</Card>
			)}


		</div>
  );
};


export default HubServicesScreen;
