import executionTracker, { type GlobalProcessingStateChangeEvent } from '@kemu-io/kemu-core/common/executionTracker';
import { useEffect, useState, useCallback } from 'react';

const useGlobalProcessingEventTracking = () => {
  // const [isProcessing, setIsProcessing] = useState(false);
  // const [isPaused, setIsPaused] = useState(false);
  const [globalState, setGlobalState] = useState<GlobalProcessingStateChangeEvent | null>(executionTracker.getLastGlobalProcessingState());
  const handleGlobalProcessingStateChange = useCallback((event: GlobalProcessingStateChangeEvent) => {
    // setIsProcessing(event.isProcessing);
    // setIsPaused(event.isPaused);
    setGlobalState(event);
  }, []);


  useEffect(() => {
    const unsubscribe = executionTracker.onGlobalProcessingStateChange(handleGlobalProcessingStateChange);

    return () => {
      unsubscribe();
    };
  }, [handleGlobalProcessingStateChange]);

  return {
    isProcessing: globalState?.isProcessing,
    isPaused: globalState?.isPaused,
    currentlyPausedWidgetId: globalState?.pauseTrapWidgetId,
    abortProcessing: executionTracker.abortProcessing,
    pauseProcessing: executionTracker.pauseProcessing,
    resumeProcessing: executionTracker.resumeProcessing,
    getLastPausedWidgetId: executionTracker.getLastPausedWidgetId,
  };
};

export default useGlobalProcessingEventTracking;
