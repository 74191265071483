import React from 'react';
import Markdown, { Components } from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { PrismLight as SyntaxHighlighter } from 'react-syntax-highlighter';
import typescript from 'react-syntax-highlighter/dist/cjs/languages/prism/typescript';
import json from 'react-syntax-highlighter/dist/cjs/languages/prism/json';
import javascript from 'react-syntax-highlighter/dist/cjs/languages/prism/javascript';
import { oneDark } from 'react-syntax-highlighter/dist/cjs/styles/prism';
import rangeParser from 'parse-numeric-range';
import globals from '@common/globals';

SyntaxHighlighter.registerLanguage('typescript', typescript);
SyntaxHighlighter.registerLanguage('json', json);
SyntaxHighlighter.registerLanguage('javascript', javascript);

type Props = {
  markdown: string;
  docsUrl: string;
}

const MarkdownViewer = (mainProps: Props) => {
  const syntaxTheme = oneDark;
  const MarkdownComponents: Components = {
    code ({ node, className, ...props }) {
      const hasLang = /language-(\w+)/.exec(className || '');
      const hasMeta = node?.data?.meta;

      // Line numbers based on: https://amirardalan.com/blog/syntax-highlight-code-in-markdown
      const applyHighlights: object = (applyHighlights: number) => {
        if (hasMeta) {
          const RE = /{([\d,-]+)}/;
          const metadata = node?.data?.meta?.replace(/\s/g, '');
          if (!metadata) { return {}; }

          const strlineNumbers = RE?.test(metadata)
            ? RE?.exec(metadata)?.[1]
            : '0';
          const highlightLines = rangeParser(strlineNumbers as string);
          const highlight = highlightLines;
          const data = highlight.includes(applyHighlights)
            ? 'highlight'
            : null;
          return { data };
        } else {
          return {};
        }
      };

      return hasLang ? (
        // @ts-expect-error ignore
        <SyntaxHighlighter
          style={syntaxTheme}
          language={hasLang[1]}
          PreTag="div"
          className="codeStyle"
          showLineNumbers={true}
          wrapLines={hasMeta}
          useInlineStyles={true}
          lineProps={applyHighlights}
        >
          {props.children}
        </SyntaxHighlighter>
      ) : (
        <code className={className} {...props} />
      );
    },

    img (props) {
      const src = props.src || '';

      // Check if the image URL starts with /docs/ and prefix it
      const imageSrc = src.startsWith('/docs/')
        ? `${globals.MARKETPLACE_PUBLIC_URL}/${mainProps.docsUrl}/${src.replace('/docs/', '')}`
        : src;

      return <img src={imageSrc} alt={props.alt || ''} />;
    }
  };

  return (
    <Markdown
      remarkPlugins={[remarkGfm]}
      components={MarkdownComponents}
    >
      {mainProps.markdown}
    </Markdown>
  );
};

export default MarkdownViewer;
