import { useCallback, useEffect, useRef } from 'react';

/**
 * @returns a function that can be used to arm a timeout.
 */
const useInvokeTimeout = () => {
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  const armTimeout = useCallback((callback: () => void, delay: number) => {
    timeoutRef.current = setTimeout(callback, delay);
  }, []);


  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  return armTimeout;
};

export default useInvokeTimeout;
