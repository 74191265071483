import React, { useEffect, useState } from 'react';
import { Button } from 'antd';
import Icon, { HolderOutlined } from '@ant-design/icons';
import Draggable, { DraggableData, DraggableEvent } from 'react-draggable';
import { useSelector, useDispatch } from 'react-redux';
import { useResizeDetector } from 'react-resize-detector';
import { Tooltip } from 'antd/lib';
import { ReactComponent as PlayIcon } from './play.svg';
import { ReactComponent as StopIcon } from './stop.svg';
import { ReactComponent as PauseIcon } from './pause.svg';
import styles from './DebuggingPanel.module.css';
import useGlobalProcessingEventTracking from '@hooks/useGlobalProcessingEventTracking';
import { selectLogicMapperSettings, setLogicMapperSettingValue } from '@src/features/interface/interfaceSlice';
import { currentRecipePoolId , selectOpeningRecipe } from '@src/features/Workspace/workspaceSlice';
import { useTranslation } from '@hooks/index';

type Props = {
  containerEl: HTMLDivElement | null;
}

const DEBUGGING_PANEL_WIDTH = 160;
const DEBUGGING_PANEL_HEIGHT = 42;
const DEBUGGING_PANEL_MARGIN = 10;

const DebuggingPanel = (props: Props) => {
  const { containerEl } = props;
  const { isProcessing, isPaused, abortProcessing, pauseProcessing, resumeProcessing } = useGlobalProcessingEventTracking();
  const userSettings = useSelector(selectLogicMapperSettings);
  const defaultPanelPosition = userSettings.debuggingPanelPosition;
  const [panelPosition, setPanelPosition] = useState(defaultPanelPosition);
  const dispatch = useDispatch();
  const t = useTranslation('DebuggingPanel');
  const recipePoolId = useSelector(currentRecipePoolId);
  const openingRecipe = useSelector(selectOpeningRecipe);
  const { ref, width, height } = useResizeDetector();

  const canShowPanel = recipePoolId && !openingRecipe;

  const handleStopDragging = (_: DraggableEvent, data: DraggableData) => {
    setPanelPosition({
      x: data.x,
      y: data.y,
    });

    dispatch(setLogicMapperSettingValue({
      key: 'debuggingPanelPosition',
      value: {
        x: data.x,
        y: data.y,
      },
    }));
  };

  useEffect(() => {
    if (containerEl) {
      ref.current = containerEl;
    }
  }, [containerEl, ref]);

  // Prevents panel from being outside the visible workspace
  useEffect(() => {
    if (width && height) {
      setPanelPosition((current) => {
        const panelX = width && width < (current.x + DEBUGGING_PANEL_WIDTH + DEBUGGING_PANEL_MARGIN) ? width - DEBUGGING_PANEL_WIDTH - DEBUGGING_PANEL_MARGIN : current.x;
        const panelY = height && height < (current.y + DEBUGGING_PANEL_HEIGHT + DEBUGGING_PANEL_MARGIN) ? height - DEBUGGING_PANEL_HEIGHT - DEBUGGING_PANEL_MARGIN : current.y;
        return {
          x: panelX,
          y: panelY,
        };
      });
    }
  }, [width, height]);

  if (!canShowPanel) { return null; }

  const canResume = (isProcessing && isPaused);
  const canStop = isProcessing;
  const canPause = isProcessing && !isPaused;

  return (
    <Draggable
      onStop={handleStopDragging}
      bounds="parent"
      handle={`.${styles.DragIcon}`}
      position={panelPosition}
    >
      <div className={styles.Container}>
        <span className={styles.DragIcon}>
          <HolderOutlined />
        </span>

        <Button.Group className={styles.ButtonGroup} size='large'>
          {/* Play */}
          <Tooltip title={t('Resume')} placement="top" open={!canResume ? false : undefined}>
            <Button
              type="text"
              icon={<Icon component={PlayIcon} />}
              disabled={!canResume}
              className={styles.PlayBtn}
              onClick={resumeProcessing}
            />
          </Tooltip>
          {/* Stop */}
          <Tooltip title={t('Stop')} placement="top" open={!canStop ? false : undefined}>
            <Button
              type="text"
              icon={<Icon component={StopIcon} />}
              onClick={abortProcessing}
              disabled={!canStop}
              className={styles.StopBtn}
            />
          </Tooltip>
          {/* Pause */}
          <Tooltip title={t('Pause')} placement="top" open={!canPause ? false : undefined}>
            <Button
              type="text"
              icon={<Icon component={PauseIcon} />}
              disabled={!canPause}
              className={styles.PauseBtn}
              onClick={pauseProcessing}
            />
          </Tooltip>
        </Button.Group>
      </div>
    </Draggable>
  );
};

export default DebuggingPanel;
