import React, { useCallback, useState } from 'react';
import 'react-contexify/dist/ReactContexify.css';
import { Menu, Item, ItemParams, PredicateParams, Separator } from 'react-contexify';
import { useDispatch, useSelector } from 'react-redux';
import { WidgetType } from '@kemu-io/kemu-core/types';
import { BrowserJsPlumbInstance } from '@jsplumb/community';
import {
	copySelectedWidgetsAction,
	duplicateWidgetAction,
	selectSelectedWidgets,
	showCreateWidgetModalAction,
	showCreateWidgetBundleModalAction,
	toggleWidgetState,
	removeWidgetsAction
} from '../logicMapperSlice.ts';
import { selectedBlock, selectVisibleGroup } from '../../Workspace/workspaceSlice';
import { clipboardHasValidKemuStructure } from '../reducers/clipboardUtils';
import { pasteFromClipboardAction } from '../reducers/clipboardReducers';
import useTranslation from '@hooks/useTranslation';
import useCoreBreakpoints from '@hooks/useCoreBreakpoints';
import { decodeDomId, DOMElementInfo } from '@common/utils';
import { selectLogicMapperSettings } from '@src/features/interface/interfaceSlice.ts';

/** Id of the context menu for gates */
export const MENU_GATE = 'gate-menu';
/** Id of the context menu for blocks */
export const MENU_BLOCK = 'block-menu';
/** Id of the context menu for the canvas */
// export const MENU_CANVAS = 'canvas-menu';
/** Id of the context menu for the logic maker */
export const MENU_LOGIC_MAKER = 'logic-maker-menu';

/** Finds the first parent node with the given className */
export const findMenuBoundary = (el: HTMLElement | undefined, className: string | undefined): HTMLElement | null => {
	let parentEl: HTMLElement | null | undefined = el;
	while (parentEl) {
		if (className && parentEl.classList.contains(className)) { return parentEl; }
		parentEl = parentEl.parentElement;
	}

	return null;
};

export interface ContextMenuItem {
	title: string;
}

export interface EventProps {
	target: HTMLElement;
	/** a class name in the parent elements to search for */
	boundary: string;
}

export interface WidgetMenuProps {
	boundaryClass: string;
	disabled: boolean;
	recipeId: string;
	gateId: string;
	blockId: string;
	gateType: WidgetType;
	returnOriginalEvent: boolean;
}

interface Props {
	plumbInstance: BrowserJsPlumbInstance | null;
}


const hideIfDisabled = ({ props, data }: PredicateParams<WidgetMenuProps>) => {
	if (data.key === 'disable-widget') {
		return !!props?.disabled;
	} else {
		return !props?.disabled;
	}
};

const hideIfNotGroupWidget = ({ props }: PredicateParams<WidgetMenuProps>) => {
	return props?.gateType !== WidgetType.widgetGroup;
};

const hideIfWidgetGroup = ({ props }: PredicateParams<WidgetMenuProps>) => {
	return props?.gateType === WidgetType.widgetGroup;
};

const hideIfNotWidgetBundle = ({ props }: PredicateParams<WidgetMenuProps>) => {
	return props?.gateType !== WidgetType.widgetBundle;
};

const ContextMenu = (props: Props): React.JSX.Element => {
	const dispatch = useDispatch();
	const selectedWidgets = useSelector(selectSelectedWidgets);
	const currentBlock = useSelector(selectedBlock);
	const [pasteEnabled, setPasteEnabled] = useState(false);
	const currentGroup = useSelector(selectVisibleGroup);
	const userSettings = useSelector(selectLogicMapperSettings);
	const { hasBreakpoint, setBreakpoint, removeBreakpoint } = useCoreBreakpoints();
	// const userSettings = useSelector(selectUserSettings);
	// const canCreateWidgetBundles = userSettings?.useWidgetBundles;

	const t = useTranslation('ContextMenu');

	const handleGateDuplication = (el: HTMLElement) => {
		const info = decodeDomId(el.id);
		if (info && info.gateId) {
			dispatch(duplicateWidgetAction({
				recipeId: info.recipeId,
				thingRecipeId: info.blockId,
				widgetThingId: info.gateId
			}));
		}
	};

	// const handleMakeOriginalEvent = (el: HTMLElement) => {
	// 	const info = decodeDomId(el.id);
	// 	if (info && info.gateId) {
	// 		dispatch(toggleWidgetOutputMode({
	// 			recipeId: info.recipeId,
	// 			blockRecipeId: info.blockId,
	// 			sourceGateId: info.gateId
	// 		}));
	// 	}
	// };

	const handleRemoveWidget = async (el: HTMLElement) => {
		const info = decodeDomId(el.id);
		const removeMultiple = Boolean(selectedWidgets.length);
		if (info && info.gateId) {
			const idsToRemove = removeMultiple ? selectedWidgets.map(w => w.widgetId) : [info.gateId];
			dispatch(removeWidgetsAction({
				recipeId: info.recipeId,
				thingId: info.blockId,
				widgetsIds: idsToRemove,
				clearSelection: removeMultiple,
			}));

			if (removeMultiple) {
				props.plumbInstance?.clearDragSelection();
			}
		}
	};

	/* const handleBlockDuplication = (el: HTMLElement) => {
		const info = decodeDomId(el.id);
		if(info && info.blockId){
			dispatch(duplicateBlock(info.blockId));
		}
	}; */


	const menuItemSelected = useCallback(({ props, data, triggerEvent }: ItemParams) => {
		const eventHandler = data.handler;
		if (!props || ! props.boundaryClass) { throw new Error('boundaryClass not defined. You probably forgot to pass the boundary class via props: show(event, {props: { boundaryClass: "myParentClass" } })'); }
		const parentEl = findMenuBoundary(triggerEvent.target as HTMLElement, props.boundaryClass);
		if (parentEl) {
			if (typeof eventHandler === 'function') {
				eventHandler(parentEl);
			} else {
				console.log('Unmapped event', eventHandler, parentEl);
			}
		}
	}, []);

	const toggleWidget = useCallback((disabled: boolean, info: DOMElementInfo | null) => {
		if (info && info.gateId) {
			dispatch(toggleWidgetState({
				recipeId: info.recipeId,
				blockRecipeId: info.blockId,
				sourceGateId: info.gateId,
				disabled: disabled
			}));
		}
	}, [dispatch]);

	const setWidgetDisabled = useCallback((el: HTMLElement) => {
		const info = decodeDomId(el.id);
		toggleWidget(true, info);
	}, [toggleWidget]);

	const setWidgetEnabled = useCallback((el: HTMLElement) => {
		const info = decodeDomId(el.id);
		toggleWidget(false, info);
	}, [toggleWidget]);

	const handleEditGroupWidget = useCallback((el: HTMLElement) => {
		const info = decodeDomId(el.id);
		if (info?.gateId) {
			dispatch(showCreateWidgetModalAction({
				recipeId: info?.recipeId,
				thingId: info?.blockId,
				widgetId: info?.gateId
			}));
		}
	}, [dispatch]);

	const handleEditWidgetBundle = useCallback((el: HTMLElement) => {
		const info = decodeDomId(el.id);
		if (info?.gateId) {
			dispatch(showCreateWidgetBundleModalAction({
				recipeId: info?.recipeId,
				thingId: info?.blockId,
				widgetId: info?.gateId
			}));
		}
	}, [dispatch]);

	const handleCreateCustomWidget = useCallback(() => {
		dispatch(showCreateWidgetModalAction(true));
	}, [dispatch]);

	// const handleCreateWidgetBundle = () => {
	// 	dispatch(showCreateWidgetBundleModalAction(true));
	// };

	const handleCopyWidgets = useCallback((el: HTMLElement) => {
		const info = decodeDomId(el.id);
		if (info?.gateId) {
			dispatch(copySelectedWidgetsAction({
				recipeId: info?.recipeId,
				thingId: info?.blockId,
				groupId: currentGroup?.groupId
			}));
		}
	}, [dispatch, currentGroup]);

	const handlePasteWidgets = useCallback(() => {
		if (currentBlock) {
			dispatch(pasteFromClipboardAction({
				recipeId: currentBlock.recipePoolId,
				thingId: currentBlock.recipeId
			}));
		}
	}, [dispatch, currentBlock]);

	const pasteDisabled = useCallback(() => {
		const checkIfValid = async () => {
			const validClipboardData = await clipboardHasValidKemuStructure();
			setPasteEnabled(validClipboardData);
		};

		checkIfValid();
		return false;
	}, []);

	const checkIfBreakpointIsEnabled = useCallback((evt: PredicateParams<WidgetMenuProps>) => {
		if (!evt.props?.gateId) { return false; }
		const alreadySet = hasBreakpoint(evt.props.gateId);
		return alreadySet === true;
	}, [hasBreakpoint]);


	const checkIfBreakpointIsDisabled = useCallback((evt: PredicateParams<WidgetMenuProps>) => {
		return !checkIfBreakpointIsEnabled(evt);
	}, [checkIfBreakpointIsEnabled]);

	const handleSetBreakpoint = useCallback((el: HTMLElement) => {
		const info = decodeDomId(el.id);
		if (info?.gateId) {
			setBreakpoint(info.gateId);
		}
	}, [setBreakpoint]);

	const handleRemoveBreakpoint = useCallback((el: HTMLElement) => {
		const info = decodeDomId(el.id);
		if (info?.gateId) {
			removeBreakpoint(info.gateId);
		}
	}, [removeBreakpoint]);

	// const hidden = ({ /* triggerEvent, */ props, data }: PredicateParams<WidgetMenuProps>) => {
	// 	// Hide option for certain gates
	// 	const forbiddenWidgets = [WidgetType.input, WidgetType.play, WidgetType.display];
	// 	if (props && forbiddenWidgets.includes(props.gateType)) { return true; }


	// 	if (data.key === 'activate-return-original') {
	// 		return !!props?.returnOriginalEvent;
	// 	} else {
	// 		return !props?.returnOriginalEvent;
	// 	}
	// };

	return (
		<>
		<Menu id={MENU_GATE}>
			<Item
				data-kemu-meta="context-menu-copy"
				key='copy'
				disabled={!selectedWidgets.length}
				onClick={menuItemSelected}
				data={{ key: 'copy-widgets', handler: handleCopyWidgets }}
			>
				{t('LogicMapper.Copy', 'Copy')}
			</Item>

			<Item
				data-kemu-meta="context-menu-editWidget"
				key='editWidget'
				hidden={hideIfNotGroupWidget}
				onClick={menuItemSelected}
				data={{ key: 'edit-group-widget', handler: handleEditGroupWidget }}
			>
				{t('LogicMapper.EditGroup', 'Edit Group')}
			</Item>

			<Item
				data-kemu-meta="context-menu-editWidgetBundle"
				key='editWidgetBundle'
				hidden={hideIfNotWidgetBundle}
				onClick={menuItemSelected}
				data={{ key: 'edit-widget-bundle', handler: handleEditWidgetBundle }}
			>
				{t('LogicMapper.EditBundle', 'Edit Bundle')}
			</Item>

			<Item
				data-kemu-meta="context-menu-disable"
				key='disable'
				hidden={hideIfDisabled}
				onClick={menuItemSelected}
				data={{ key: 'disable-widget', handler: setWidgetDisabled }}
			>
				{t('LogicMapper.Disable', 'Disable')}
			</Item>

			<Item
				data-kemu-meta="context-menu-enable"
				key='enable'
				hidden={hideIfDisabled}
				onClick={menuItemSelected}
				data={{ key: 'enable-widget', handler: setWidgetEnabled }}
			>
				{t('LogicMapper.Enable', 'Enable')}
			</Item>

			<Item
				data-kemu-meta="context-menu-duplicate"
				key='duplicate'
				hidden={hideIfWidgetGroup}
				onClick={menuItemSelected}
				data={{ handler: handleGateDuplication }}
			>
				{t('LogicMapper.Duplicate', 'Duplicate')}
			</Item>

			{/* <Item
				data-kemu-meta="context-menu-return-original-evt"
				key='return-original-evt'
				hidden={hidden}
				onClick={menuItemSelected}
				data={{ key: 'activate-return-original', handler: handleMakeOriginalEvent }}
			>
				{t('LogicMapper.ReturnOriginalEvent', 'Return Original Event')}
			</Item> */}

			{/* <Item
				data-kemu-meta="context-menu-return-result-evt"
				key='return-result-evt'
				hidden={hidden}
				onClick={menuItemSelected}
				data={{ key: 'cancel-return-original', handler: handleMakeOriginalEvent }}
			>
				{t('LogicMapper.ReturnProcessedEvent', 'Return Processed Event')}
			</Item> */}

			<Item
				data-kemu-meta="context-menu-remove-widget"
				key='remove-widget'
				onClick={menuItemSelected}
				data={{ handler: handleRemoveWidget }}>
				{selectedWidgets.length ? (
					t('LogicMapper.RemoveSelected', 'Remove Selected')
				) : (
					t('LogicMapper.Remove', 'Remove')
				)}
			</Item>

			{userSettings.showDebuggingPanel && (
				<Separator />
			)}

			<Item
				data-kemu-meta="context-menu-breakpoint-add-breakpoint"
				key='add-breakpoint'
				onClick={menuItemSelected}
				hidden={!userSettings.showDebuggingPanel || checkIfBreakpointIsEnabled}
				data={{ handler: handleSetBreakpoint }}
			>
				{t('LogicMapper.AddBreakpoint')}
			</Item>

			<Item
				data-kemu-meta="context-menu-breakpoint-remove-breakpoint"
				key='remove-breakpoint'
				onClick={menuItemSelected}
				hidden={!userSettings.showDebuggingPanel || checkIfBreakpointIsDisabled}
				data={{ handler: handleRemoveBreakpoint }}
			>
				{t('LogicMapper.RemoveBreakpoint')}
			</Item>
		</Menu>

		{/* <Menu id={MENU_BLOCK}>
			<Item data-kemu-meta="context-menu-duplicate-widget" key='b1' onClick={menuItemSelected} data={handleBlockDuplication}>
				{t('LogicMapper.Canvas.Duplicate', 'Duplicate')}
			</Item>
		</Menu> */}

		<Menu id={MENU_LOGIC_MAKER}>
			<Item
				data-kemu-meta="context-menu-paste"
				key='paste'
				hidden={pasteDisabled}
				disabled={!pasteEnabled}
				onClick={menuItemSelected}
				data={{ key: 'paste-widgets', handler: handlePasteWidgets }}
			>
				{t('LogicMapper.Paste', 'Paste')}
			</Item>

			<Item
				data-kemu-meta="create-custom-widget-menu"
				key='lm_create_widget'
				onClick={menuItemSelected}
				data={{ handler: handleCreateCustomWidget }}
			>
				{t('Canvas.CreateCustomWidget', 'Create custom widget')}
			</Item>

			{/* <Item
				data-kemu-meta="create-widget-bundle-menu"
				key='lm_create_widget-bundle'
				onClick={menuItemSelected}
				hidden={!canCreateWidgetBundles}
				data={{ handler: handleCreateWidgetBundle }}
			>
				{t('Canvas.CreateWidgetBundle', 'Create widget bundle')}
			</Item> */}
		</Menu>
		</>
	);
};

export default ContextMenu;
