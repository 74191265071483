import { useMemo, useState } from 'react';
import { clearFileHandle, getFileHandle, setFileHandle as setServiceFileHandle } from '@src/services/fileHandleService';

const useFileHandle = () => {
  const [fileHandle, setFileHandle] = useState<FileSystemFileHandle | null>(getFileHandle());

  const result = useMemo(() => ({
    fileHandle,
    setFileHandle: (handle: FileSystemFileHandle | null) => {
      if (handle) {
        setServiceFileHandle(handle);
      } else {
        clearFileHandle();
      }
      setFileHandle(handle);
    }
  }), [fileHandle]);

  return result;
};

export default useFileHandle;
