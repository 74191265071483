import React, { useMemo, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { DEFAULT_THING_ID, DEFAULT_THING_VERSION } from '@kemu-io/kemu-core/common/constants';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Layout } from 'antd';
import { useNavigate } from 'react-router-dom';
import { currentRecipePoolId, selectOpeningRecipe } from '../Workspace/workspaceSlice';
import LogicMapperSection from '@components/layout/HorizontalSplit/LogicMapperSection';
import { SelectedBlockInfo } from '@src/types/core_t';
import MainMenu from '@components/mainMenu/mainMenu';
import routes from '@common/routes';


const DesktopRecipeScreen = (): React.JSX.Element | null => {
  const recipePoolId = useSelector(currentRecipePoolId);
  const openingRecipe = useSelector(selectOpeningRecipe);
  const navigate = useNavigate();

  const defaultThing: SelectedBlockInfo = useMemo(() => {
    return {
      DbId: DEFAULT_THING_ID,
      recipeId: DEFAULT_THING_ID,
      recipePoolId: recipePoolId || '',
      title: '',
      version: DEFAULT_THING_VERSION,
    };
  }, [recipePoolId]);


  useEffect(() => {
    if (!recipePoolId) {
      navigate(routes.getBaseRoute());
    }
  }, [recipePoolId, navigate]);

  if (!recipePoolId) { return null; }

  return (
    <DndProvider backend={HTML5Backend}>
      <div id="layout-container" className="light-theme">
        <Layout className={'layout-section'} style={{ flexDirection: 'row' }}>
          <MainMenu collapsed={true} />
          {!openingRecipe && (
            <LogicMapperSection
              openedBlock={defaultThing}
              recipePoolId={recipePoolId}
              visible={true}
            />
          )}
        </Layout>
      </div>
    </DndProvider>
  );
};

export default DesktopRecipeScreen;
