/*
 * Written by Alexander Agudelo < alex.agudelo@kemu.com >, 2021
 * Date: 04/Feb/2021
 * Last Modified: 04/03/2025, 6:21:46 pm
 * Modified By: Alexander Agudelo
 * Description:  Displays image and numeric data
 * 
 * ------
 * Copyright (C) 2021 Kemu - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential.
 */

import React, { useRef, useEffect, useCallback, useState } from 'react';
import classNames from 'classnames';
import Icon from '@ant-design/icons';
import { Resizable, ResizeCallbackData } from 'react-resizable';
import DisplayWidgetProcessor, { DisplayGateState, getDefaultState } from '@kemu-io/kemu-core/widgets/display/index.js';
import { WidgetPortContext } from '@kemu-io/kemu-core/types';
import GateIcon from '../../gateIcon/gateIcon';
import { GetPortsInformationFunction, GateUI, GateUIProps } from '../index';
import 'react-resizable/css/styles.css';
import ParentEventHandler from '../helpers/ParentEventHandler/ParentEventHandler';
import styles from './display.module.css';
import ResizeHandle from '@components/ResizeHandle/ResizeHandle';
import { ABORT_DRAGGING_CLASS } from '@common/constants';
import useReactiveWidgetState from '@common/hooks/useReactiveWidgetState';
import { ReactComponent as ElapsedGateIcon } from '@src/assets/img/gates/display.svg';


const DEFAULT_W = 100;
const DEFAULT_H = 40;

const DisplayGate = (props: GateUIProps): React.JSX.Element => {
	const { recipeId, thingRecipeId, info, repaint } = props;
	const { id: gateId } = info;
	const [gateState, setGateState] = useReactiveWidgetState<DisplayGateState>(recipeId, thingRecipeId, gateId);
	const canvasRef = useRef<HTMLCanvasElement | null>(null);
	const canvasContext = useRef<CanvasRenderingContext2D | null>(null);
	const { videoMode, lastValue, scaledWidth, scaledHeight } = {
		...getDefaultState(),
		...gateState
	};
	const [rawDimensions, setRawDimensions] = useState<{width: number, height: number} | null>(null);
	const [showDimensions, setShowDimensions] = useState(false);
	const [isResizing, setIsResizing] = useState(false);
	const lastImageData = useRef<ImageData | null>(null);

	const handleResize = (event: React.SyntheticEvent<Element, Event> & { srcElement?: HTMLElement}, data: ResizeCallbackData) => {
		const newWidth = data.size.width;
		const newHeight = data.size.height;

		setGateState((currentState) => ({
			...currentState,
			videoMode: true,
			scaledWidth: newWidth,
			scaledHeight: newHeight,
		}));

		repaint();
	};

	const handleResizeStart = useCallback(() => {
		setIsResizing(true);
	}, []);

	const handleResizeEnd = useCallback(() => {
		setIsResizing(false);
		// Fix aspect ratio
		if (rawDimensions && scaledWidth && scaledHeight) {
			const { width, height } = rawDimensions;
			const rawImageRatio = width / height;
			const scaledImageRatio = scaledWidth / scaledHeight;
			if (rawImageRatio != scaledImageRatio) {
				setGateState((currentState) => ({
					...currentState,
					videoMode: true,
					scaledWidth: currentState.scaledWidth,
					scaledHeight: (currentState.scaledWidth || 1) / rawImageRatio
				}));
			}
		}

		repaint();
	}, [repaint, rawDimensions, scaledWidth, scaledHeight, setGateState]);



	const handleDimensionsClick = useCallback(() => {
		setShowDimensions(s => !s);
	}, []);

	const handleImageDataEvent = useCallback((imageData: ImageData/* , sourceWidgetId: string */) => {
		// IMPORTANT: make sure 'imageData' is NOT used inside of the setRawDimensions function,
		// doing so would capture imageData in the context and prevent it from being released
		// causing a memory leak after just a few minutes.
		const idW = imageData.width;
		const idH = imageData.height;

		// Detect a significant change in the aspect ratio and compensate for it
		const imageRatio = idW / idH;
		const currentRatio = (gateState.scaledWidth || 1) / (gateState.scaledHeight || 1);
		if (Math.abs(imageRatio - currentRatio) > 0.1) {
			setGateState((currentState) => ({
				...currentState,
				videoMode: true,
				scaledWidth: gateState.scaledWidth,
				scaledHeight: (gateState.scaledWidth || 1) / imageRatio
			}));

			requestAnimationFrame(repaint);
		}

		// Keep track of the real image dimensions
		setRawDimensions(currentRow => {
			if (!currentRow || (idW !== currentRow.width || idH !== currentRow.height)) {
				return {
					width: idW,
					height: idH
				};
			}

			return currentRow;
		});

		if (!videoMode) {
			setGateState(currentState => ({
				...currentState,
				videoMode: true
			}));
		}


		if (canvasRef.current && canvasContext.current && !isResizing) {
			canvasRef.current.width = imageData.width;
			canvasRef.current.height = imageData.height;
			canvasContext.current.putImageData(imageData, 0, 0);
		} else {
			// If at this point, it likely means the previous mode was numeric
			// and the canvas reference does not exist. Here we keep a reference
			// to the image data so we can paint it once the canvas element is mounted.
			lastImageData.current = imageData;
		}
	}, [videoMode, gateState, setGateState, isResizing, repaint]);


	const handleNumericEvent = useCallback((value: number | string) => {
		if (gateState.videoMode || gateState.lastValue !== value) {
			setGateState({
				...gateState,
				videoMode: false,
				lastValue: value
			});
		}

	}, [gateState, setGateState]);

	/** Refresh the canvas context when the mode changes */
	useEffect(() => {
		if (canvasRef.current && videoMode) {
			canvasContext.current = canvasRef.current.getContext('2d');
			// If there is image data pending for painting, paint now!
			if (lastImageData.current && canvasContext.current) {
				canvasRef.current.width = lastImageData.current.width;
				canvasRef.current.height = lastImageData.current.height;
				canvasContext.current.putImageData(lastImageData.current, 0, 0);
				lastImageData.current = null;
			}
		}

		// Every time the mode changes, the dimensions change and we need to update the port location
		repaint();
	}, [videoMode, repaint/* , scaledWidth, scaledHeight */]);

	return (
		<>
			{videoMode ? (
				<Resizable
					height={gateState.scaledHeight || DEFAULT_H}
					width={gateState.scaledWidth || DEFAULT_W}
					minConstraints={[DEFAULT_W, DEFAULT_H]}
					resizeHandles={['se']}
					onResize={handleResize}
					onResizeStart={handleResizeStart}
					onResizeStop={handleResizeEnd}
					lockAspectRatio={true}
					handle={<ResizeHandle />}
				>
					<div
						className={classNames(styles.GateBody, ABORT_DRAGGING_CLASS)}
						style={{
							width: gateState.scaledWidth || DEFAULT_W,
							height: gateState.scaledHeight || DEFAULT_H
						}}
					>
						<canvas
							className={styles.Canvas}
							ref={canvasRef}
						/>
						{!!rawDimensions && (
							<div
								className={classNames(styles.ResolutionView, { [styles.AlwaysVisible]: showDimensions })}
								onClick={handleDimensionsClick}
							>
								{rawDimensions.width}x{rawDimensions.height}
							</div>
						)}
					</div>
				</Resizable>
			): (
				<div className={classNames(styles.GateBody,styles.LcdContainer)}>
					<div className={styles.Lcd}>
						{lastValue}
					</div>
				</div>
			)}

			<ParentEventHandler
				onImageData={handleImageDataEvent}
				onNumericValue={handleNumericEvent}
				recipeId={recipeId}
				blockRecipeId={thingRecipeId}
				gateId={gateId}
			/>
		</>
	);
};

/** Icon to be added to the bar */
const GateBarIcon = (): React.JSX.Element => {
	return (
		<GateIcon icon={<Icon component={ElapsedGateIcon} />}/>
	);
};


const getPortsInformation: GetPortsInformationFunction = (state, widgetInfo) => {
	const portContext: WidgetPortContext = {
		recipePoolId: widgetInfo.recipePoolId,
		recipeType: widgetInfo.recipeType,
		thingRecipeId: widgetInfo.thingRecipeId,
		widgetId: widgetInfo.id,
	};
	const inputNames = DisplayWidgetProcessor.getInputNames(state, portContext);

	return {
		inputs: [{
			position: 'Left',
			type: inputNames[0].type,
			name: inputNames[0].name
		}],

		outputs: [],
	};
};


export default {
	getPortsInformation,
	BarIcon: GateBarIcon,
	Element: DisplayGate,
	hasTitle: true,
	getWidgetTitle: (intl) => intl.formatMessage({ defaultMessage: 'Display', id: 'LogicMaker.Gates.Display.Title' }),
} as GateUI;
