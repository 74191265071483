import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { notification } from 'antd';
import { selectNotification, showGlobalNotification } from '@src/features/interface/interfaceSlice';


const GlobalNotifications = () => {
  const [api, contextHolder] = notification.useNotification();
  const info = useSelector(selectNotification);
  const dispatch = useDispatch();

  useEffect(() => {
    if (info) {
      api.open({
        type: info.type,
        message: info.title,
        description: info.message,
        placement: 'topRight',
        duration: info.duration,
        key: info.code,
        onClose: () => {
          api.destroy();
        },
      });

      dispatch(showGlobalNotification(null));
    }

  }, [info, api, dispatch]);

  return contextHolder;
};

export default GlobalNotifications;
