import { useEffect, useState } from 'react';
import executionTracker from '@kemu-io/kemu-core/common/executionTracker';

type BreakpointStatus = 'active' | 'disabled' | 'unset';

/**
 * Returns the status of a breakpoint for a widget
 * @param widgetId - The id of the widget to check the breakpoint status for
 * @returns The status of the breakpoint for the widget
 */
const useBreakpointStatus = (widgetId?: string) => {
  const [breakpointStatus, setBreakpointStatus] = useState<BreakpointStatus>('unset');

  useEffect(() => {
    if (widgetId) {
      const breakpointStatus = executionTracker.hasBreakpoint(widgetId);
      setBreakpointStatus(breakpointStatus === null ? 'unset' : (breakpointStatus ? 'active' : 'disabled'));
      return executionTracker.onBreakpointChange(({ breakpoints }) => {
        const status = breakpoints[widgetId] as boolean | undefined;
        setBreakpointStatus(status === undefined
          ? 'unset'
          : (status ? 'active' : 'disabled'));
      });
    }
  }, [widgetId]);

  return breakpointStatus;
};

export default useBreakpointStatus;
