import React, { useCallback, useEffect, useRef, useState } from 'react';
import notification from 'antd/lib/notification';
import { DEFAULT_THING_ID } from '@kemu-io/kemu-core/common/constants';
import { useDispatch } from 'react-redux';
import { toggleWidgetState } from '../logicMapperSlice';
import styles from './LogicErrorNotification.module.css';
import useProcessingErrorDetection, { type ProcessorException } from '@hooks/useProcessingErrorDetection';
import { useTranslation } from '@hooks/index';
import useNavigateToWidgetPath from '@hooks/useNavigateToWidgetPath';
import useCurrentRecipeInfo from '@hooks/useCurrentRecipeInfo';

type NotificationContext = ProcessorException | null;
type ErrorHistory = {
  widgetId: string;
  widgetType: string;
  widgetName: string;
  groupId?: string;
  timestamp: number;
}

const DISABLED_WIDGET_DURATION = 1000;
const MAX_ERRORS = 3;

const Context = React.createContext<NotificationContext>(null);

const LinkToWidget = ({
  widgetId,
  widgetName
}: {
  widgetId: string;
  widgetName: string;
}) => {
  const navigateToWidgetPath = useNavigateToWidgetPath();

  return <a onClick={() => navigateToWidgetPath({ widgetId })}>{widgetName}</a>;
};

const NotificationBody = ({
  widgetId,
  widgetName,
  groupId,
  isService,
  error,
  customMessage
}: {
  widgetId: string;
  widgetName: string;
  groupId?: string;
  isService?: boolean;
  customMessage?: string;
  error?: string;
}) => {
  const t = useTranslation('LogicErrorNotification');

  return (
    <div className={styles.Body}>
      {error ? (
        <>
          {groupId ? (
            <LinkToWidget
              widgetId={widgetId}
              widgetName={widgetName}
            />
          ) : (
            <span className={styles.WidgetName}>
              {widgetName}
            </span>
          )}


          <span>
            {' '}{t('ErrorEncountered', undefined, {
              widgetName: isService ? t.withBaseKey('CommonWords')('Service') : ''
          })}:
          </span><br />
          <span className={styles.ErrorMessage}>{error}</span>
        </>
      ) : (
        <span className={styles.ErrorMessage}>{customMessage}</span>
      )}

    </div>
  );
};

const LogicErrorNotification = () => {
  const [api, contextHolder] = notification.useNotification({
    stack: {
      threshold: 3,
    }
  });

  const [exception, clearException] = useProcessingErrorDetection();
  // Keeps track of last 10 errors to allow detecting UI blocking errors
  const [lastErrors, setLastErrors] = useState<ErrorHistory[]>([]);
  const t = useTranslation('LogicErrorNotification');
  const dispatch = useDispatch();
  const currentRecipe = useCurrentRecipeInfo();


  /** 
   * Checks if any widget has produced 3 or more errors in the last 2 seconds
   * and returns that widget's ID if found, or null if none found.
   */
  const findRepeatingError = useCallback((): ErrorHistory | null => {
    const now = Date.now();
    const counts: Record<string, number> = {};

    for (const error of lastErrors) {
      // Skip old errors
      if (now - error.timestamp >= DISABLED_WIDGET_DURATION) { continue; }

      counts[error.widgetId] = (counts[error.widgetId] || 0) + 1;

      // Return early if we find a widget with 3+ errors
      if (counts[error.widgetId] >= MAX_ERRORS) {
        return error;
      }
    }

    return null;
  }, [lastErrors]);

  const openNotification = useCallback((exc: ProcessorException) => {
    const widgetName = exc.widgetInfo.hubService?.title || exc.widgetInfo.type;
    // Add the error to the list
    setLastErrors((prev) => {
      const newErrors = [...prev, {
        widgetId: exc.widgetInfo.id,
        widgetType: exc.widgetInfo.type,
        widgetName,
        groupId: exc.widgetInfo.groupId,
        timestamp: Date.now(),
      }];

      if (newErrors.length > 10) {
        newErrors.shift();
      }

      return newErrors;
    });

    api.error({
      message: t('Title'),
      duration: 0,
      className: styles.Notification,
      description: (
        <Context.Consumer>
          {() => {
            if (!exc) { return null; }

            return (
              <NotificationBody
                widgetId={exc.widgetInfo.id}
                widgetName={widgetName}
                groupId={exc.widgetInfo.groupId}
                isService={!!exc.widgetInfo.hubService}
                error={exc.error}
              />
            );
          }}
        </Context.Consumer>
      ),
      placement: 'bottomRight',
    });

    clearException();
  }, [api, t, clearException]);

  useEffect(() => {
    if (exception) {
      openNotification(exception);
    }
  }, [exception, openNotification]);

  /**
   * Disables the widget that has produced 3 or more errors in the last 2 seconds.
   * and displays a notification.
   */
  useEffect(() => {
    const repeatingError = findRepeatingError();
    if (repeatingError && currentRecipe.poolId) {
      dispatch(toggleWidgetState({
				recipeId: currentRecipe.poolId,
				blockRecipeId: DEFAULT_THING_ID,
				sourceGateId: repeatingError.widgetId,
				disabled: true
			}));

      api.warning({
        message: t('WidgetDisabled.Title'),
        placement: 'bottomRight',
        className: styles.Notification,
        duration: 0,
        description: (
          <NotificationBody
            widgetId={repeatingError.widgetId}
            groupId={repeatingError.groupId}
            widgetName={repeatingError.widgetName}
            customMessage={t('WidgetDisabled.Description', undefined, {
              name: <LinkToWidget widgetId={repeatingError.widgetId} widgetName={repeatingError.widgetName} />
            })}
          />
        ),
      });
    }
  }, [findRepeatingError, currentRecipe.poolId, dispatch, api, t]);

  return (
    <Context.Provider value={exception}>
      {contextHolder}
    </Context.Provider>
  );
};

export default LogicErrorNotification;
