import { useEffect, useState } from 'react';
import executionTracker, { type ExecutionError } from '@kemu-io/kemu-core/common/executionTracker';

const useWidgetHasError = (widgetId: string) => {
  const [error, setError] = useState<ExecutionError | null>(null);
  const lastError = executionTracker.getLastError();

  useEffect(() => {
    if (lastError?.targetWidgetId === widgetId || !lastError) {
      setError(lastError);
    } else {
      setError(null);
    }
  }, [lastError, widgetId]);

  return error;
};

export default useWidgetHasError;
