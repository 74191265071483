/**
 * The default session id a Kemu Hub
 */
export const KemuHubServiceId = 0;

/**
 * Class name used by the canvas container.
 * Added globally as they are a few components that depend on it.
 */
export const CANVAS_DRAGGABLE_CLASS = 'canvas-background';

/**
 * If present, removes the canvas background grid
 */
export const CANVAS_NO_GRID_CLASS = 'no-grid';

/**
 * Extra class added to the logic mapper's canvas instance to differentiate it from the
 * workspace's canvas.
 */
export const LOGIC_MAPPER_CANVAS_CLASS = 'lm-canvas';

/**
 * Class added to widgets when they are selected via 'useDragSelection'
 */
export const WIDGET_SELECTED_CLASS = 'widget-selected';

/**
 * Class added to target ports
 */
export const TARGET_PORT_CLASS = 'target-port';

/**
 * Class added to source ports
 */
export const SOURCE_PORT_CLASS = 'source-port';

/**
 * Class added to widgets
 */
export const CANVAS_WIDGET_CLASS = 'canvas-gate';

/**
 * Class added to widgets that should not be selected
 */
export const WIDGET_ABORT_DRAG_SELECTION = 'no-selection';

/**
 * Marks the container as allowed to start selection
 */
export const CANVAS_SELECTION_START = 'selection-start';

/**
 * Element where widgets are added and the one that gets scaled when zooming
 */
export const CANVAS_VIEWPORT_CLASS = 'canvas-viewport';

/**
 * Container of the canvas viewport
 */
export const CANVAS_VIEWER_CLASS = 'canvas-viewer';

/**
 * Indicates the widget renders a custom UI.
 */
export const CUSTOM_HUB_SERVICE_UI_CLASS = 'custom-hs-ui';

/**
 * Static class that SHOULD not be modified as
 * it is used by multiple modules to find elements inside the
 * widgets bar (Eg, hotspots).
 */
export const WIDGETS_BAR_CLASS = 'gates-bar';

/**
 * Prevents global css from affecting certain elements
 */
export const NO_GLOBAL_RULES_CLASS = 'no-glo-rls';

/**
 * Users need to press this key in order to activate dragging
 */
export const CANVAS_DRAG_KEY_MAC_OS = 'Alt';
export const CANVAS_DRAG_KEY_WIN_OS = 'Control';

export const MIDDLE_BUTTON_INDEX = 4;
export const RIGHT_BUTTON_INDEX = 2;

// export const GET_CANVAS_DRAG_KEY = (): string => isMacOs() ? CANVAS_DRAG_KEY_MAC_OS : CANVAS_DRAG_KEY_WIN_OS;
export const GET_CANVAS_DRAG_BUTTON_INDEX = (): number => MIDDLE_BUTTON_INDEX;

/**
 * Used by things (aka apps/blocks) to identify the root container.
 * Mostly used to know where to locate alerts. 
 * 
 * NOTE: some 'Things' depend on this class to position elements on the screen.
 * By default they should be using `document._KEMU_.CANVAS_CONTAINER_CLASS` to access
 * this value.
 */
export const CANVAS_CONTAINER_CLASS = 'canvas-container';
export const LM_CANVAS_CONTAINER_CLASS = 'lm-canvas-container';
/** class added to the container that covers right side of the layout */
export const SITE_LAYOUT_RIGHT_SECTION_CLASS = 'layout-right';
/** 
 * identifies not input type components as inputs, allowing standard styling
 * reserved for input types to be applied to the element.
 **/
export const LM_COMPONENT_INPUT_TYPE_CLASS = 'is-input';

/** class prefix used by ports of custom widgets */
export const CUSTOM_WIDGET_PORT_COLOR_PREFIX = 'port-color-';
/** indicates the given port has a custom color */
export const CUSTOM_WIDGET_PORT_COLOR_FLAG = 'custom-col';

/**
 * Class added to gate ports to identify them as such.
 */
export const GATE_PORT_CLASS = 'gate-port';

/**
 * Indicates the port is a trigger port
 */
export const TRIGGER_PORT_CLASS = 'trigger-port';

/**
 * Any element with this class will abort dragging of its parent (if a draggable, Eg, Widgets, Things, Annotations)
 */
export const ABORT_DRAGGING_CLASS = 'no-dragging';

/**
 * Any child node of an element with this class will not trigger dragging.
 */
export const ABORT_CHILD_DRAGGING = 'no-child-drag';

/**
 * Prevents widget groups from opening on double click
 */
export const ABORT_OPEN_WIDGET_CLICK = 'no-open-wdg';

/**
 * Identifies the container of Widget groups with fields
 */
export const WIDGET_GROUP_FIELDS_CONTAINER_CLASS = 'g-body-fields-cnt';
/**
 * The attribute assigned to 'individual' type organizations in the user profile.
 * This is used to determine whether the user belongs to an organization or if they
 * are individuals.
 */
export const INDIVIDUAL_ORGANIZATION_ATTR = 'individual';

/**
 * Indicates a widget is disabled
 */
export const DISABLED_WIDGET_CLASS = 'widget-disabled';

/**
 * Indicates a widget service is offline
 */
export const OFFLINE_SERVICE_CLASS = 'service-offline';
/**
 * A class added to the settings container of all widgets.
 */
export const SETTINGS_CONTAINER_CLASS = 'settings-container';

/**
 * If present, it indicates the widget is returning the original event.
 */
export const ORIGINAL_EVENT_CLASS = 'original-event';

/**
 * Common properties used by the chat widget to set user properties.
 */
export const USER_CHAT_PROPERTIES = {
	LastOpenedRecipeId: 'recipeId',
	LastOpenedRecipeName: 'recipeName'
};

/** Default size of ports */
export const WIDGET_PORT_RADIUS = 7;

/** Name of the FontAwesome icon to display when a widget has no default icon */
export const WIDGET_DEFAULT_ICON_NAME = 'folder';

/** css class added to all connections between Things */
export const THING_CONNECTION_CLASS = 'block-connector';
export const WIDGET_CONNECTION_CLASS = 'widget-connector';

/** Added when a widget was recently invoked */
export const WIDGET_INVOKED_CLASS = 'widget-invoked';
/**
 * NOTE: To prevent affecting ALL things in case some of these constants are changed,
 * we append them to the document, so that things can refer to them.
 */
type DocumentWithKemuConstants = Document & {
	_KEMU_: {
		[key: string]: string
	}
};

(document as DocumentWithKemuConstants)._KEMU_ = {
	...((document as DocumentWithKemuConstants)._KEMU_ || {}),
	CANVAS_CONTAINER_CLASS: CANVAS_CONTAINER_CLASS,
	CANVAS_DRAGGING_ABORT_CLASS: ABORT_DRAGGING_CLASS,
	CANVAS_ABORT_CHILD_DRAGGING_CLASS: ABORT_CHILD_DRAGGING,
};


export const DroppableTypes = {
	Widget: 'WIDGET',
	CustomWidget: 'CUSTOM_WIDGET',
	Annotation: 'ANNOTATION',
	TutorialStep: 'TUTORIAL_STEP',
};


export const TutorialProtocols = {
	v1: 'v1',	// Resources include the full S3 path.
	v2: 'v2', // Resources now have a relative path and viewers need to prepend the correct domain.
	v3: 'v3', // Assets now have a relative path without the userId or tutorial id.
};

export const getLatestTutorialVersion = (): string => {
	const latest = Object.keys(TutorialProtocols).sort().pop() as keyof typeof TutorialProtocols;
	return TutorialProtocols[latest];
};

export const HubServiceCacheName = 'hub-services';
// IMPORTANT: keep in sync with `service-worker.ts`
export const HubServiceCachePrefix = '/local-hub-service';
