import React, { useCallback, useEffect, useRef, useState } from 'react';
import type { ExecutionError, ExecutionStateChangeEvent } from '@kemu-io/kemu-core/common/executionTracker';
import { Spin, Tooltip } from 'antd';
import { CheckCircleFilled, ExclamationCircleFilled } from '@ant-design/icons';
import { RecipeWidget } from '@kemu-io/kemu-core/types';
import { useDebouncedCallback } from 'use-debounce';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import styles from './WidgetProcessingWrapper.module.css';
import useWidgetEventTracking from '@hooks/useWidgetEventTracking';
import { selectLogicMapperSettings } from '@src/features/interface/interfaceSlice';
import useGlobalProcessingEventTracking from '@hooks/useGlobalProcessingEventTracking';
import useBreakpointStatus from '@hooks/useBreakpointStatus';
import useTranslation from '@hooks/useTranslation';
import useWidgetHasError from '@hooks/useLastExecutionError';

type Props = {
  widgetInfo: RecipeWidget;
  hidden?: boolean;
  children: React.ReactNode;
}

const WidgetProcessingWrapper = (props: Props) => {
  const { widgetInfo, children, hidden } = props;
  const [isProcessing, setIsProcessing] = useState(false);
  const [error, setError] = useState<ExecutionError | null>();
  const [showTick, setShowTick] = useState(false);
  const tickTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const t = useTranslation('WidgetProcessingWrapper');
  const userSettings = useSelector(selectLogicMapperSettings);
  const showSuccessTick = userSettings.showSuccessTick;
  const showWidgetProcessingAnimations = userSettings.showWidgetProcessingAnimations;
  const noAnimations = hidden || (!showSuccessTick && !showWidgetProcessingAnimations);
  const { isProcessing: globalProcessing, isPaused, currentlyPausedWidgetId } = useGlobalProcessingEventTracking();
  // Check if the widget that got caught in the current pause trap is this widget
  const pausedOnThisWidget = isPaused && globalProcessing && currentlyPausedWidgetId === widgetInfo.id;
  const breakpointStatus = useBreakpointStatus(widgetInfo.id);
  const hasBreakpointSet = breakpointStatus !== 'unset';
  const widgetHasError = useWidgetHasError(widgetInfo.id);

  const toggleEventDebounced = useDebouncedCallback((event: ExecutionStateChangeEvent) => {
    setIsProcessing(event.newState.isProcessing);
    setError(event.newState.error || null);
  }, 100);


  const handleTrackingEvent = useCallback((event: ExecutionStateChangeEvent) => {
    if (noAnimations) { return; }
    const wasProcessing = event.prevState.isProcessing;
    const isProcessing = event.newState.isProcessing;

    toggleEventDebounced(event);

    if (isProcessing) {
      setShowTick(false);
      if (tickTimeoutRef.current) {
        clearTimeout(tickTimeoutRef.current);
      }
    }

    if (wasProcessing && !isProcessing && !event.newState.error) {
      tickTimeoutRef.current = setTimeout(() => {
        setShowTick(true);
      }, 200);
    }
  }, [toggleEventDebounced, noAnimations]);


  useWidgetEventTracking({
    widgetId: widgetInfo.id,
    callback: handleTrackingEvent,
  });


  useEffect(() => {
    return () => {
      if (tickTimeoutRef.current) {
        clearTimeout(tickTimeoutRef.current);
      }
    };
  }, []);

  useEffect(() => {
    if (globalProcessing) {
      setShowTick(false);
    }
  }, [globalProcessing]);

  useEffect(() => {
    if (widgetHasError) {
      setError((c) => c ? c : widgetHasError);
    }
  }, [widgetHasError]);

  return (
    <div className={classNames(styles.Wrapper, { [styles.Paused]: pausedOnThisWidget && !hidden })}>

      {children}

      {!noAnimations && (
        <>
          {isProcessing && !widgetInfo.disabled && showWidgetProcessingAnimations && (
            <div className={styles.ProcessingOverlay}>
              <Spin size="large" />
            </div>
          )}

          {error && (
            <div className={styles.ErrorContainer}>
              <Tooltip title={error.error} placement="top">
                <ExclamationCircleFilled className={styles.ErrorIcon} />
              </Tooltip>
            </div>
          )}

          {hasBreakpointSet && (
            <div className={styles.BreakpointContainer}>
              <Tooltip title={t('BreakpointActive')} placement="top">
                <div className={styles.BreakpointIcon} />
              </Tooltip>
            </div>
          )}

          {showTick && showSuccessTick && !globalProcessing && (
            <div className={styles.TickContainer}>
              <CheckCircleFilled className={styles.TickIcon} />
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default WidgetProcessingWrapper;
