import executionTracker, { type ExecutionStateChangeListener } from '@kemu-io/kemu-core/common/executionTracker';
import { useEffect } from 'react';
import { DEFAULT_THING_ID } from '@kemu-io/kemu-core/common/constants';

const useWidgetEventTracking = (config: {
  widgetId: string,
  callback: ExecutionStateChangeListener;
}) => {
  const { widgetId, callback } = config;


  useEffect(() => {
    const unsubscribe = executionTracker.onWidgetExecutionStateChange({
      thingId: DEFAULT_THING_ID,
      widgetId,
      listener: callback,
    });

    // Notify about the initial state
    const currentState = executionTracker.getExecutionState({ thingId: DEFAULT_THING_ID, widgetId });
    callback({
      thingId: DEFAULT_THING_ID,
      widgetId,
      prevState: currentState,
      newState: currentState,
    });

    return () => {
      unsubscribe();
    };
  }, [widgetId, callback]);
};

export default useWidgetEventTracking;
