import React, { useCallback, useEffect, useRef } from 'react';
import { Layout } from 'antd';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import Canvas from '../../canvas/canvas';
// import 'react-reflex/styles.css';
import Breadcrumb from '../../breadcrumb/breadcrumb';
import styles from './LogicMapperSection.module.css';
import LogicMapperContextProvider from '@common/context/LogicMapperContext/LogicMapperContext';
import WidgetsBar from '@src/features/LogicMapper/WidgetsBar/WidgetsBar';
import { SelectedBlockInfo } from '@src/types/core_t';
import useJSPlumb from '@hooks/useJsPlumb';
import LogicMapper from '@src/features/LogicMapper/LogicMapper';
// import SplitPaneButtons from '@src/features/LogicMapper/SplitPaneButtons/SplitPaneButtons';
import { selectVisibleGroup } from '@src/features/Workspace/workspaceSlice';
import ContextMenu from '@src/features/LogicMapper/contextMenu/contextMenu';
import { LOGIC_MAPPER_CANVAS_CLASS, SITE_LAYOUT_RIGHT_SECTION_CLASS, WIDGET_CONNECTION_CLASS } from '@common/constants';
import RecipeWorkspaceTitle from '@components/RecipeWorkspaceTitle/RecipeWorkspaceTitle';
import { selectLogicMapperSettings } from '@src/features/interface/interfaceSlice';
import WidgetPortInspector from '@src/features/LogicMapper/WidgetPortInspector/WidgetPortInspector';
import DebuggingPanel from '@components/DebuggingPanel/DebuggingPanel';
import LogicErrorNotification from '@src/features/LogicMapper/LogicErrorNotification/LogicErrorNotification';


type Props = {
  visible: boolean;
  recipePoolId: string | null;
  openedBlock: SelectedBlockInfo | null;
  onLogicMapperMounted?: () => void;
}

const { Content } = Layout;

const LogicMapperSection = (props: Props): React.JSX.Element => {
  const { visible, recipePoolId, openedBlock, onLogicMapperMounted } = props;
  const visibleGroup = useSelector(selectVisibleGroup);
  const logicMapperSettings = useSelector(selectLogicMapperSettings);
  const sectionContainerRef = useRef<HTMLDivElement>(null);
  // const zoomRef = useRef<number>(1);

  const {
    containerEl: logicMapperContainerEl,
    initialize: initLogicMapperJsPlumb,
    jspInstance: logicMapperJsPlumb,
  } = useJSPlumb({ connectionClass: WIDGET_CONNECTION_CLASS });

  const handleZoomChange = useCallback((zoom: number) => {
    if (logicMapperJsPlumb) {
      logicMapperJsPlumb.setZoom(zoom);
    }
  }, [logicMapperJsPlumb]);

  /* useEffect(() => {
    // Create an event listener for mouse wheel
    const handleMouseWheel = (e: WheelEvent) => {
      if (e.ctrlKey) {
        e.preventDefault();
        if (logicMapperContainerEl && logicMapperJsPlumb) {
          const direction = e.deltaY > 0 ? -1 : 1;
          const ratio = 0.1;
          const zoom = zoomRef.current + direction * ratio;
          console.log('zoom', zoom);
          logicMapperJsPlumb.setZoom(zoom);
          logicMapperContainerEl.style.transform = `scale(${zoom})`;
          zoomRef.current = zoom;

          const canvasEl = logicMapperContainerEl.querySelector(`.${LOGIC_MAPPER_CANVAS_CLASS}`) as HTMLElement;
          if (canvasEl) {
            canvasEl.style.width = `${300 * zoom}%`;
            canvasEl.style.height = `${300 * zoom}%`;
          }
        }
      }
    };

    window.addEventListener('wheel', handleMouseWheel, { passive: false });

    return () => {
      window.removeEventListener('wheel', handleMouseWheel);
    };
  }, [logicMapperContainerEl, logicMapperJsPlumb]); */

  return (
    <LogicMapperContextProvider value={{ jsPlumb: logicMapperJsPlumb }}>
      <div className={styles.Section}>
        {visible && (
          <Layout className={classNames(SITE_LAYOUT_RIGHT_SECTION_CLASS, styles.RightSide)}>
            {recipePoolId && (
              <WidgetsBar recipePoolId={recipePoolId}/>
            )}
            <Content
              ref={sectionContainerRef}
              className={classNames(
                styles.LayoutContent,
                styles.LogicMapperContainer, {
                  'custom-widget': !!visibleGroup
                }
              )
            }>

              {logicMapperSettings.showDebuggingPanel && (
                <DebuggingPanel containerEl={sectionContainerRef.current} />
              )}

              <div className={styles.RecipeNameContainer}>
                <RecipeWorkspaceTitle />
              </div>

              {logicMapperSettings.portInspectorVisible && (
                <WidgetPortInspector containerEl={sectionContainerRef.current} />
              )}

              <LogicErrorNotification />

              <Breadcrumb />
              <ContextMenu plumbInstance={logicMapperJsPlumb} />
              {/* <SplitPaneButtons /> */}
              {recipePoolId && openedBlock && (
                <Canvas
                  ref={initLogicMapperJsPlumb}
                  onZoomChange={handleZoomChange}
                  recipeId={recipePoolId}
                  selectedBlock={openedBlock}
                  loadingArea="logic-mapper"
                >
                  {logicMapperJsPlumb && (
                    <LogicMapper
                      plumbInstance={logicMapperJsPlumb}
                      containerEl={logicMapperContainerEl}
                      thingRecipeId={openedBlock.recipeId}
                      thingDbId={openedBlock.DbId}
                      thingVersion={openedBlock.version}
                      recipeId={recipePoolId}
                      onMounted={onLogicMapperMounted}
                    />
                  )}

                  {/* <AnnotationsManager recipeId={recipePoolId} /> */}
                </Canvas>
              )}
            </Content>
          </Layout>
        )}
      </div>
    </LogicMapperContextProvider>
  );
};

export default LogicMapperSection;
