
import React, { MouseEventHandler, useCallback, useEffect, useRef, useState } from 'react';
import Icon, { PlusOutlined } from '@ant-design/icons';
import { nanoid } from 'nanoid';
import ImageFilterProcessor, {
	ImageFilterState,
	FilterItem,
	getDefaultState
} from '@kemu-io/kemu-core/widgets/imageFilter/index.js';
import { WidgetState, WidgetPortContext } from '@kemu-io/kemu-core/types';
import classNames from 'classnames';
import { Button, Select, InputNumber } from 'antd';
import {
  DndContext,
  closestCenter,
  PointerSensor,
  useSensor,
  useSensors,
	DragEndEvent,
	DragStartEvent,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { PortLocation } from '../../../types/canvas_t.ts';
import GateIcon from '../../gateIcon/gateIcon.tsx';
import {
	GetPortsInformationFunction,
	GateUI,
	GateCustomSettingsProps,
} from '../index.ts';
import { ABORT_DRAGGING_CLASS, SETTINGS_CONTAINER_CLASS } from '../../../common/constants.ts';
import styles from './imageFilter.module.css';
import { ReactComponent as ImageFilterIcon } from './icon.svg';
import FilterRow from './FilterRow/FilterRow.tsx';
import useReactiveWidgetState from '@hooks/useReactiveWidgetState.ts';
import WidgetBodyWithIcon from '@components/WidgetBodyWithIcon/WidgetBodyWithIcon.tsx';

const ImageFilter = (/* props: GateUIProps */): React.JSX.Element => {
	return (
		<WidgetBodyWithIcon icon={ImageFilterIcon} className={styles.CanvasBody} />
	);
};

/** Icon to be added to the bar */
const GateBarIcon = (): React.JSX.Element => {
	return (
		<GateIcon icon={<Icon component={ImageFilterIcon} />}/>
	);
};

const ValidFilters = ['blur', 'brightness', 'contrast', 'grayscale', 'hue-rotate', 'invert', 'opacity', 'saturate', 'sepia'];
const getFilterId = () => nanoid(6);

const GateCustomSettings = (props: GateCustomSettingsProps): React.JSX.Element => {
	const mouseDownPos = useRef<{ x: number, initVal: number, id: string } | null>(null);
	const [editingValue, setEditingValue] = useState<FilterItem | null>(null);
	const [draggingId, setDraggingId] = useState<string | null>(null);
	const popupContainerRef = useRef<HTMLDivElement>(null);
	const [tempFilter, setTempFilter] = useState<FilterItem>({ name: 'brightness', value: 0.5, id: getFilterId() });
	const [state, setState] = useReactiveWidgetState<ImageFilterState>(props.recipeId, props.blockId, props.gateInfo.id);
	const sensors = useSensors(
    useSensor(PointerSensor),
    // useSensor(KeyboardSensor, {
    //   coordinateGetter: sortableKeyboardCoordinates,
    // })
  );

	const fixedState = {
		...getDefaultState(),
		...state
	};

	const handleValueMouseDown: MouseEventHandler<HTMLDivElement> = useCallback((event) => {
		const filterId = event.currentTarget.dataset.filterId;
		const filter = fixedState.filters.find((f) => f.id === filterId);
		if (filter) {
			mouseDownPos.current = {
				x: event.clientX,
				initVal: filter.value,
				id: filter.id,
			};
		}
	}, [fixedState.filters]);

	/** Makes the clicked item editable */
	const handleDoubleClick: MouseEventHandler<HTMLDivElement> = useCallback((event) => {
		const filterId = event.currentTarget.dataset.filterId;
		const filter = fixedState.filters.find((f) => f.id === filterId);
		if (filter) {
			setEditingValue({ ...filter });
		}
	}, [fixedState.filters]);


	const handleTmpFilterValueChange = useCallback((value: number | string | null) => {
		if (value !== null) {
			setTempFilter((t) => ({ ...t, value: Number(value) }));
		}
	}, []);

	const handleDropdownChange = useCallback((name: string) => {
		setTempFilter((t) => ({ ...t, name }));
	}, []);

	const clearEditingValue = useCallback(() => {
		setEditingValue(null);
	}, []);

	const handleAddFilter = useCallback(() => {
		setState((s) => ({
			...s,
			filters: [...s.filters, tempFilter],
		}));

		// Configure a new temp filter
		setTempFilter({ name: '', value: 0.5, id: getFilterId() });
		clearEditingValue();
	}, [tempFilter, setState, clearEditingValue]);

	const handleRemoveFilter = useCallback((id: string) => {
		setState((s) => ({
			...s,
			filters: s.filters.filter((f) => f.id !== id),
		}));
	}, [setState]);

	const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;

    if (active.id !== over?.id) {
			setDraggingId(null);
			setState((s) => {
				if (!over?.id) { return s; }
				const filters = s.filters;
				const oldIndex = filters.findIndex((f) => f.id === active.id);
				const newIndex = filters.findIndex((f) => f.id === over.id);

				return {
					...s,
					filters: arrayMove(filters, oldIndex, newIndex),
				};
			});
    }
  };

	const handleDragStart = (event: DragStartEvent) => {
		setDraggingId(event?.active.id as string);
	};

	useEffect(() => {
		const handleMouseUp = () => {
			mouseDownPos.current = null;
		};

		const handleMouseMove = (event: MouseEvent) => {
			if (mouseDownPos.current) {
				setState((s) => {
					if (mouseDownPos.current) {
						const dx = (event.clientX - mouseDownPos.current.x) * 0.001;
						const newVal = Number((mouseDownPos.current.initVal + dx).toFixed(2));
						return {
							...s,
							filters: s.filters.map((f) => f.id === mouseDownPos.current?.id ? { ...f, value: newVal } : f),
						};
					}

					return s;
				});
			}
		};

		document.addEventListener('mouseup', handleMouseUp);
		document.addEventListener('mousemove', handleMouseMove);

		return () => {
			document.removeEventListener('mouseup', handleMouseUp);
			document.removeEventListener('mousemove', handleMouseMove);
		};
	}, [setState, fixedState.filters]);

	return (
		<div className={classNames(styles.SettingsContainer, SETTINGS_CONTAINER_CLASS)}>
			{props.children}

			<div className={classNames(styles.DropdownContainer, ABORT_DRAGGING_CLASS)}>
				<div className={styles.Dropdown} ref={popupContainerRef}>
					<Select
						getPopupContainer={() => popupContainerRef.current as HTMLElement}
						value={tempFilter.name}
						style	={{ width: '100%' }}
						onChange={handleDropdownChange}
						options={ValidFilters.map((filter) => ({ value: filter, label: filter }))}

					/>
				</div>
				<InputNumber
					className={styles.DropValue}
					min={-1.0}
					max={1.0}
					step={0.01}
					value={tempFilter.value}
					onChange={handleTmpFilterValueChange}
				/>
				<Button
					shape="default"
					type='default'
					icon={<PlusOutlined />}
					onClick={handleAddFilter}
					disabled={!tempFilter.name}
					className={styles.AddBtn}
				/>
			</div>
			<div className={classNames(styles.FiltersContainer, ABORT_DRAGGING_CLASS)}>
				<DndContext
					sensors={sensors}
					collisionDetection={closestCenter}
					onDragEnd={handleDragEnd}
					onDragStart={handleDragStart}
				>
					<SortableContext items={fixedState.filters} strategy={verticalListSortingStrategy}>
						{fixedState.filters.map((filter, index) => (
							<FilterRow
								key={`${filter.name}-${index}`}
								filter={filter}
								editingValue={editingValue}
								handleDoubleClick={handleDoubleClick}
								setState={setState}
								clearEditingValue={clearEditingValue}
								setEditingValue={setEditingValue}
								handleValueMouseDown={handleValueMouseDown}
								handleRemoveFilter={handleRemoveFilter}
							/>

						))}
					</SortableContext>
				</DndContext>
			</div>
		</div>
	);
};



const getPortsInformation: GetPortsInformationFunction = (state: WidgetState<ImageFilterState>, widgetInfo, intl) => {
	const portContext: WidgetPortContext = {
		recipePoolId: widgetInfo.recipePoolId,
		recipeType: widgetInfo.recipeType,
		thingRecipeId: widgetInfo.thingRecipeId,
		widgetId: widgetInfo.id,
	};

	const outputNames = ImageFilterProcessor.getOutputNames(state, portContext);
	const inputNames = ImageFilterProcessor.getInputNames(state, portContext);

	const inputPositions: Record<string, PortLocation> = {
		'image': 'Left'
	};

	return {
		inputs: inputNames.map(input => ({
			name: input.name,
			type: input.type,
			position: inputPositions[input.name],
		})),

		outputs: outputNames.map(output => ({
			name: output.name,
			type: output.type,
			position: 'Right',
			label: output.label
		})),
	};
};

export default {
	getPortsInformation,
	BarIcon: GateBarIcon,
	Element: ImageFilter,
	CustomSettingsDialog: GateCustomSettings,
	getWrapperClass: () => styles.Wrapper,
	hasTitle: true,
	getWidgetTitle: (intl) => intl.formatMessage({ id: 'LogicMapper.Widgets.ImageFilter.CanvasTitle', defaultMessage: 'IMG Filter' }),
	getGatesBarTitle: (intl) => intl.formatMessage({ id: 'LogicMapper.Widgets.ImageFilter.BarTitle', defaultMessage: 'Image Filter' }),
} as GateUI;
