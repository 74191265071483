import React from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { selectRecipeDownloadDetails } from '../../features/interface/interfaceSlice';
import StreamOperationIndicator from '../streamOperationIndicator/streamOperationIndicator';
import { AsyncRequestStatus } from '../../types/core_t';

type MessageTypes = 'success' | 'progress' | 'error';

const RecipeDownloadIndicator = (): React.JSX.Element | null => {
	const intl = useIntl();
	const asyncOperation = useSelector(selectRecipeDownloadDetails);
	const downloadStatus = asyncOperation?.status;
	let message = intl.formatMessage({ id: 'DownloadRecipe.Progress', defaultMessage: 'Downloading recipe contents...' });
	let messageType: MessageTypes = 'progress';

	if (downloadStatus === AsyncRequestStatus.completed) {
		messageType = 'success';
		message = intl.formatMessage({ id: 'DownloadRecipe.Success', defaultMessage: 'Recipe downloaded!' });
	} else if (downloadStatus === AsyncRequestStatus.error) {
		messageType = 'error';
		message = intl.formatMessage({ id: 'DownloadRecipe.Failure', defaultMessage: 'Failed to open the recipe' });
	}

	if (!downloadStatus) { return null; }
	const combinedPercentage = (asyncOperation?.contentProgress || 0) + (asyncOperation?.storageProgress || 0);
	return (
		<StreamOperationIndicator
			errorReason={asyncOperation?.error?.message}
			message={message}
			percent={Math.floor(combinedPercentage/2)}
			type={messageType}
		/>
	);
};

export default RecipeDownloadIndicator;
