import React from 'react';
import Menu from 'antd/lib/menu';
import Layout from 'antd/lib/layout';
import { useNavigate } from 'react-router-dom';
import Icon from '@ant-design/icons';
import classNames from 'classnames';
import routes from '../../../../common/routes/index';
import { ReactComponent as RecipeIcon } from '../../../../assets/img/menus/recipe.svg';
import useTranslation from '../../../../common/hooks/useTranslation';
import styles from './MarketplaceMenu.module.css';
import useQueryString from '@hooks/useQueryString';
import DynamicIcon from '@components/dynamicIcon/dynamicIcon';


const { Sider } = Layout;

const MenuItem = (props: {
  targetPath: string,
  selected: boolean,
  label: string,
  dataKemuMeta: string,
  icon: React.ReactNode,
}) => {
  const navigate = useNavigate();

  const navigateToTab = (route: string) => {
		navigate(route);
	};

  return (
    <Menu.Item
      data-kemu-meta={props.dataKemuMeta}
      onClick={() => navigateToTab(props.targetPath)}
      className={classNames(styles.MenuItem, {
        [styles.MenuItemSelected]: props.selected,
      })}
      // Required for proper styling (an antd thing)
      {...props}
    >
      {props.label}
    </Menu.Item>
  );
};

const MarketplaceMenu = (): React.JSX.Element => {
  const t = useTranslation('Marketplace.Navigation.LeftPanel');
  const queryStr = useQueryString();
	const inRecipes = queryStr.get('tab') === 'recipe';
	const inHubServices = queryStr.get('tab') === 'hub-services';

  return (
    <Sider className={styles.Sider} theme='light' width={200} breakpoint={'md'} collapsedWidth={0}>
      {/* ID added for specificity reasons only. ant styles were overriding my custom ones */}
      <Menu  mode="inline" className={`sidebar-menu`} selectable={false} id={styles.Menu}>
        <MenuItem
          targetPath={routes.marketplace.getMyRecipesRoute()}
          selected={inRecipes}
          label={t('Recipes')}
          dataKemuMeta="recipes"
          icon={<Icon component={RecipeIcon} />}
        />

        <MenuItem
          targetPath={routes.marketplace.getHubServicesRoute()}
          selected={inHubServices}
          label={t.withBaseKey('CommonWords')('HubServices')}
          dataKemuMeta="hub-services"
          icon={<DynamicIcon iconName="cube" />}
        />
      </Menu>
    </Sider>
  );
};

export default MarketplaceMenu;
