import { ActionReducerMapBuilder, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { RecipeType, SaveRecipeRequestInfo, ThingType, RecipeWidgetMeta } from '@kemu-io/kemu-types';
import { WidgetGroupState, WidgetState, WidgetType } from '@kemu-io/kemu-core/types';
import { NavigateFunction } from 'react-router-dom';
import { InterfaceState, setAsyncOperationDetails } from '../interfaceSlice';
import { setRecipeMetaAction, setRecipeNeedsSaving } from '../../Workspace/workspaceSlice';
import { generateRecipePackage } from '../../../common/recipeActions/saveRecipe';
import { uploadRecipeStorageAction } from './uploadRecipeStorageReducer';
import { uploadRecipeContentsAction } from './uploadRecipeContentsReducer';
import routes from '@common/routes/index';
// import { getRecipeStorage, prepareRecipeForSaving } from '@src/app/recipe/utils';
import { AsyncRequestStatus } from '@src/types/core_t';
import { USER_CHAT_PROPERTIES } from '@common/constants';
import getFreshChatWidget from '@src/app/recipe/freshChatWidget';
import * as recipeApi from '@src/api/recipe/recipeApi';
import { RootState } from '@src/app/store';

// 100MB limit
const REQUEST_LIMIT = 1024 * 1024 * 100;

/**
 * Uploads a recipe to the server.
 * If the recipe uses a storage unit, it uses multipart upload.
 * @param options.recipePoolId the id of the recipe in the pool
 * @returns the sha1 of the combined storage (if present)
 */
export const storeRecipeAction = createAsyncThunk('/interface/storeRecipe', async (options: {
	recipePoolId: string,
	newName?: string,
	hideProgress?: boolean
	navigate: NavigateFunction,
	saveAsNew?: boolean
}, thunkAPI): Promise<string | null> => {

	// // TODO: Currently, we don't update the id or author info in the recipe content's file 
	// // because at the time of generating the checksum we don't know what version number of id
	// // the recipe will have, so if we were to update the id after the server responds, the checksum
	// // would no longer match. This however creates a lot of confusion because the Cached recipe instance
	// // does contain id and author properties that we are supposed to ignore. 
	// const { contents, dbId, recipe: parsedRecipe } = await prepareRecipeForSaving(options.recipePoolId, options.newName);

	// const recipeStorage = getRecipeStorage(options.recipePoolId);

	// // Combine all storage units
	// let storageSize = 0;
	// let checksum = '0';
	// let disk: ArrayBuffer | undefined;
	// if (recipeStorage) {
		// 	// WARNING: heavy operation
		// 	disk = storageMapToUnit(recipeStorage);
		// 	if (disk.byteLength) {
			// 		storageSize = disk.byteLength;
			// 		// WARNING: heavy operation 2
			// 		checksum = MD5ArrayBuffer.hash(disk);
			// 	}
			// }

	const { contents, storage, parsedContents: recipeCopy } = await generateRecipePackage(options.recipePoolId, options.newName);

	const dbId = recipeCopy.id;
	const blocksKeys = Object.keys(recipeCopy.blocks);
	const storageSize = storage?.size || 0;
	const checksum = storage?.checksum || '0';

	const widgetsMeta: RecipeWidgetMeta[] = [];
	blocksKeys.forEach(blockId => {
		const widgets = recipeCopy.blocks[blockId].gates;
		Object.keys(widgets).forEach(widgetId => {
			const widget = recipeCopy.blocks[blockId].gates[widgetId];
			widgetsMeta.push({
				// TODO: Replace once users can rename widgets
				name: widget.type === WidgetType.widgetGroup ? (widget.state as WidgetState<WidgetGroupState>).name : widget.type,
				thingId: blockId,
				type: widget.type,
			});
		});
	});

	const parsedRecipe: SaveRecipeRequestInfo = {
		// id: recipeCopy.id,
		name: recipeCopy.name,
		dataSources: recipeCopy.dataSources,
		protocolVersion: recipeCopy.protocolVersion,
		blocks: blocksKeys.map((blockId) => {
			// Some older recipes (before cloud recipes were introduced - and later decommissioned -)
			// had a `thing.type` that was equal to the thing's id and did not match the current type (cloud, web).
			// Here we make sure that if an older recipe is opened and saved, the thing type is updated to match
			// a valid type (default web);
			const thisThing = recipeCopy.blocks[blockId];
			if (thisThing.type !== ThingType.Cloud && thisThing.type !== ThingType.Web) {
				thisThing.type = ThingType.Web;
			}

			return {
				name: thisThing.name,
				category: thisThing.category,
				id: thisThing.id,
				type: thisThing.type,
				version: thisThing.version,
			};
		}),

		widgets: widgetsMeta,
	};

	// Prevent showing upload notification
	if (!options.hideProgress) {
		// Show saving notification
		thunkAPI.dispatch(setAsyncOperationDetails({ action: 'save', extra: !!storage?.disk }));
	}

	// Append cloud environment info to the recipe (if any)
	const { workspace } = thunkAPI.getState() as RootState;
	if (workspace.currentRecipe.cloud) {
		parsedRecipe.cloud = { ...workspace.currentRecipe.cloud };
	}

	// Make sure the contents don't surpass the request limit
	const encoder = new TextEncoder();
	const contentsBuffer = encoder.encode(contents);
	if (contentsBuffer.byteLength > REQUEST_LIMIT) { throw new Error(`Recipe contents exceed the allowed limit`); }


	const contentAb = contentsBuffer.buffer.slice(contentsBuffer.byteOffset, contentsBuffer.byteOffset + contentsBuffer.byteLength) as ArrayBuffer;
	const recipeName = options.newName || parsedRecipe.name;
	const canUploadStorage = (uploadUrl?: string) => !!(uploadUrl && storage?.disk !== undefined && storage.disk.byteLength);

	const handleDiskUpload = (uploadUrl?: string) => {
		// If we need to upload the contents, dispatch a new event
		if (storage?.disk !== undefined && storage.disk.byteLength && uploadUrl) {
			return thunkAPI.dispatch(uploadRecipeStorageAction({ name: recipeName, disk: storage.disk, uploadUrl }));
		}
	};

	// Will store the final id of the recipe
	let recipeID: string;

	if (!dbId || options.newName || options.saveAsNew) {
		const response = await recipeApi.saveRecipe(parsedRecipe, contentsBuffer.byteLength, storageSize, checksum );
		const uploadStorage = canUploadStorage(response.storageUrl);

		// Update the ID of the local recipe with that provided by the server
		// setRecipeMeta(options.recipeId, {
		// 	id: response.recipeId,
		// 	version: response.version,
		// 	name: recipeName,
		// });

		thunkAPI.dispatch(setRecipeMetaAction({
			id: response.recipeId,
			version: response.version,
			name: recipeName,
			recipeType: workspace.currentRecipe.cloud ? RecipeType.Cloud : RecipeType.Browser,
		}));

		// Also update the title of the recipe
		// thunkAPI.dispatch(setRecipeName(recipeName));
		// Upload contents
		await thunkAPI.dispatch(uploadRecipeContentsAction({
			contents: contentAb,
			uploadUrl: response.contentsUrl,
			hasStorage: uploadStorage,
		}));

		// Upload storage
		if (uploadStorage && storage?.disk && response.storageUrl) {
			// await thunkAPI.dispatch(uploadRecipeStorageAction({ name: recipeName, disk, uploadUrl: response.storageUrl }));
			await handleDiskUpload(response.storageUrl);
		}

		recipeID = response.recipeId;
	} else {
		const response = await recipeApi.updateRecipe(parsedRecipe, dbId, contentsBuffer.byteLength, storageSize, checksum);
		// The server determines based on the checksum if contents need to be uploaded
		const uploadDiskAgain = canUploadStorage(response.storageUrl);

		// Upload contents
		await thunkAPI.dispatch(uploadRecipeContentsAction({
			contents: contentAb,
			uploadUrl:
			response.contentsUrl,
			hasStorage: uploadDiskAgain,
		}));

		if (!uploadDiskAgain && !options.hideProgress) {
			thunkAPI.dispatch(setAsyncOperationDetails({ extra: false }));
		}

		// Upload storage
		// TODO: The first time a recipe is opened, the disk ids are re-generated,
		// this causes the storage checksum to change and forces the user to re-upload
		// the disk even though it hasn't really changed.
		await handleDiskUpload(response.storageUrl);
		recipeID = dbId;
	}

	// TODO: Refactor? having this here is ugly!
	// Update the user properties in the chat widget. This allows support
	// personnel to know which recipe the user is looking at.
	const freshChatInstance = getFreshChatWidget();
	if (freshChatInstance.initialized && recipeID) {
		freshChatInstance.setUserProperty({
			[USER_CHAT_PROPERTIES.LastOpenedRecipeId]: recipeID,
			[USER_CHAT_PROPERTIES.LastOpenedRecipeName]: recipeName,
		});
	}

	// Show the created id in the url
	if (workspace.currentRecipe.type === RecipeType.Browser) {
		options.navigate(routes.recipe.getBrowserRecipeRoute(recipeID));
	} else if (workspace.currentRecipe.type === RecipeType.Cloud) {
		options.navigate(routes.recipe.getCloudRecipeRoute(recipeID));
	}

	// Indicates the recipe no longer needs to be saved
	await thunkAPI.dispatch(setRecipeNeedsSaving(false));
	return checksum;
});


export const storeRecipeReducer = ((builder: ActionReducerMapBuilder<InterfaceState>): void => {
	builder.addCase(storeRecipeAction.pending, (state) => {
		state.asyncOperation = {
			...state.asyncOperation,
			status: AsyncRequestStatus.loading,
			error: undefined
		};
	});

	builder.addCase(storeRecipeAction.fulfilled, (state, action: PayloadAction<string | null >) => {
		state.asyncOperation = { ...state.asyncOperation, status: AsyncRequestStatus.completed };
		state.lastStorageChecksum = action.payload;
	});

	builder.addCase(storeRecipeAction.rejected, (state, action) => {
		console.log('Error storing recipe: ', action.error);
		state.asyncOperation = {
			...state.asyncOperation,
			status: AsyncRequestStatus.error,
			error: action.error
		};
	});
});
