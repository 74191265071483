// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { useCallback, useEffect, useRef, useState } from 'react';
import SelectionArea, { SelectionEvent } from '@viselect/vanilla';
import { BrowserJsPlumbInstance } from '@jsplumb/community';
import { useDispatch } from 'react-redux';
import {
	CANVAS_SELECTION_START,
	CANVAS_VIEWPORT_CLASS,
	CANVAS_WIDGET_CLASS,
	GET_CANVAS_DRAG_BUTTON_INDEX,
	LM_CANVAS_CONTAINER_CLASS,
	LOGIC_MAPPER_CANVAS_CLASS,
	RIGHT_BUTTON_INDEX,
	WIDGET_SELECTED_CLASS,
	WIDGET_ABORT_DRAG_SELECTION
} from '../constants.ts';
import {
	SelectedWidgetInfo,
	setMouseLocationAction,
	setSelectedWidgetsAction
} from '../../features/LogicMapper/logicMapperSlice.ts';
import { decodeDomId } from '../utils';

export type UseDragSelectionResponse = {
	clearSelection: () => void;
}

// const startAreaSelector = `.${CANVAS_DRAGGABLE_CLASS}.${LOGIC_MAPPER_CANVAS_CLASS}`;
const startAreaSelector = `.${LM_CANVAS_CONTAINER_CLASS} .${LOGIC_MAPPER_CANVAS_CLASS}`;

const useDragSelection = (
	containerRef: HTMLDivElement | null,
	plumbInstance: BrowserJsPlumbInstance | null,
	folderPath?: string,
): UseDragSelectionResponse => {
	const dispatch = useDispatch();
	const [vs, setVs] = useState<InstanceType<typeof SelectionArea> | null>(null);
	const selectedItemsRef = useRef<Element[]>([]);
	const isDragging = useRef<boolean>(false);

	const markElementsSelected = useCallback((items: Element[]) => {
		items.forEach(el => {
			if (el.classList.contains(WIDGET_ABORT_DRAG_SELECTION)) {
				return;
			}

			el.classList.add(WIDGET_SELECTED_CLASS);
			plumbInstance?.addToDragSelection(el.id);
		});
	}, [plumbInstance]);

	const markElementsNotSelected = useCallback((items: Element[]) => {
		items.forEach(el => {
			el.classList.remove(WIDGET_SELECTED_CLASS);
			plumbInstance?.removeFromDragSelection(el.id);
		});
	}, [plumbInstance]);

	// Keeps track of the location relative to the drag area the user clicked on.
	const mouseDownHandler = useCallback((event: Event) => {
		const mouseEvent = (event as MouseEvent);
		// const targetRect = (event.target as HTMLElement).getBoundingClientRect();
		// Position is always relative to the viewer container (the element that gets scaled)
		const viewport = containerRef?.classList.contains(CANVAS_VIEWPORT_CLASS) ? containerRef : containerRef?.querySelector(`.${CANVAS_VIEWPORT_CLASS}`);
		if (viewport) {
			const targetRect = viewport.getBoundingClientRect();
			dispatch(setMouseLocationAction({
				x: mouseEvent.clientX - targetRect.left,
				y: mouseEvent.clientY - targetRect.top,
			}));
		}
	}, [dispatch, containerRef]);

	const mouseClickHandler = useCallback((event: Event) => {
		if (vs) {
			if (event.target) {
				if (isDragging.current) {
					// if ((event.target as HTMLElement).classList.contains(CANVAS_DRAGGABLE_CLASS)) {
					if ((event.target as HTMLElement).classList.contains(CANVAS_SELECTION_START)) {
						markElementsNotSelected(selectedItemsRef.current);
						selectedItemsRef.current = [];
						vs.clearSelection();
						dispatch(setSelectedWidgetsAction([]));
					}
					isDragging.current = false;
				}
			}
		}
	}, [vs, dispatch, markElementsNotSelected]);

	const initDragSelect = useCallback(() => {

		const instance = new SelectionArea({
			selectionAreaClass: 'selection-area',
			selectables: `.${CANVAS_WIDGET_CLASS}`,
			boundaries: `.${LM_CANVAS_CONTAINER_CLASS}`,
			startAreas: startAreaSelector,
			features: {
				// A single click does not select the element
				singleTap: {
					allow: false,
					intersect: 'native'
				}
			}
		});


		// Only allow to drag if drag key is pressed
		instance.on('beforestart', (action: SelectionEvent) => {
			// Abort selection if not directly on the drag area
			// if (!(action.event?.target as HTMLElement).classList.contains(LOGIC_MAPPER_CANVAS_CLASS)) {
			if (!(action.event?.target as HTMLElement).classList.contains(CANVAS_SELECTION_START)) {
				// Allow disabled elements to start selection
				if (!(action.event?.target as HTMLElement).closest(`.${WIDGET_ABORT_DRAG_SELECTION}`)) {
					return false;
				}

			}

			const eventButton = (action?.event as & { buttons?: number })?.buttons;

			// Abort if dragging
			if (eventButton === GET_CANVAS_DRAG_BUTTON_INDEX()) {
				return false;
			}

			// Abort if right button
			if (eventButton === RIGHT_BUTTON_INDEX) {
				return false;
			}

			isDragging.current = true;
			return true;
		});


		instance.on('stop', () => {
			// Update the redux store with the list of selected items
			const decodedItems: SelectedWidgetInfo[] = [];
			selectedItemsRef.current.forEach(item => {
				const itemInfo = decodeDomId(item.id);
				if (itemInfo?.gateId) {
					decodedItems.push({
						widgetId: itemInfo.gateId
					});
				}
			});

			isDragging.current = false;
			dispatch(setSelectedWidgetsAction(decodedItems));
		});

		instance.on('start', ({ selection, event, store }) => {
			if (!event?.shiftKey) {
				markElementsNotSelected(selectedItemsRef.current);
				selection.clearSelection();
				selectedItemsRef.current = [];
				store.stored = [];
			} else {
				// re-load the store with the current items
				store.stored = selection.getSelection();
			}
		});

		instance.on('move', ({ store }) => {
			markElementsSelected(store.changed.added);
			markElementsNotSelected(store.changed.removed);

			selectedItemsRef.current = store.stored.concat(store.selected);
		});

		setVs(instance);

	}, [dispatch, markElementsSelected, markElementsNotSelected]);


	const clearSelection = useCallback(() => {
		if (vs) {
			markElementsNotSelected(selectedItemsRef.current);
			vs.clearSelection(true);
			selectedItemsRef.current = [];
			// Also update the state
			dispatch(setSelectedWidgetsAction([]));
		}
	}, [vs, markElementsNotSelected, dispatch]);

	useEffect(() => {
		if (!vs && plumbInstance && containerRef) {
			initDragSelect();
		}

		const dragAreaElement = document.querySelector(startAreaSelector);
		// Wait for the drag element to exist
		if (dragAreaElement) {
			dragAreaElement.addEventListener('click', mouseClickHandler);
			dragAreaElement.addEventListener('mousedown', mouseDownHandler);
		}

		return () => {
			if (dragAreaElement) {
				dragAreaElement.removeEventListener('click', mouseClickHandler);
				dragAreaElement.removeEventListener('mousedown', mouseDownHandler);
			}

			if (vs) {
				vs.destroy();
				setVs(null);
			}
		};

	}, [plumbInstance, vs, containerRef, initDragSelect, mouseDownHandler, mouseClickHandler]);

	// Clear out selection when the folder path changes
	useEffect(() => {
		plumbInstance?.clearDragSelection();
	}, [folderPath, plumbInstance]);

	return {
		clearSelection
	};
};

export default useDragSelection;
