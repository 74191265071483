import { DEFAULT_THING_ID } from '@kemu-io/kemu-core/common/constants';
import { RecipeWidget, WidgetGroupState } from '@kemu-io/kemu-core/types';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getWidgetsInThing } from '@src/app/recipe/utils';
import { setCurrentWidgetAction } from '@src/app/reducers/widget/widgetSlice';
import { FolderPathInfo, gotoBlockRootAction, selectCurrentRecipe, setFolderPath } from '@src/features/Workspace/workspaceSlice';
import { WidgetsMap } from '@src/types/core_t';

const getGroupInfo = (groupId: string, widgets: WidgetsMap): FolderPathInfo | null => {
  const group = widgets[groupId];
  if (!group) {
    console.error(`Group [${groupId}] not found in recipe`);
    return null;
  }

  const state = group.state as WidgetGroupState;
  return {
    groupId,
    name: state.name,
    description: state.description,
  };
};
/**
 * @returns a function that can be used to create a valid breadcrumb path to access
 * widgets inside folders (custom widgets or nested widgets )
 */
const useNavigateToWidgetPath = () => {
  const recipe = useSelector(selectCurrentRecipe);
  const dispatch = useDispatch();

  const navigateToWidgetPath = useCallback((config: {
    /** 
     * the id of the target widget to access.
     * NOTE: this is NOT the parent widget but the widget itself.
     **/
    widgetId: string;
  }) => {

    const currentWidgets = getWidgetsInThing(recipe?.poolId || '', DEFAULT_THING_ID);
    const targetWidget = currentWidgets[config.widgetId];

    if (!targetWidget) {
      console.error(`Widget [${config.widgetId}] not found in recipe`);
      return;
    }

    // The widget is NOT inside a group
    if (!targetWidget.groupId) {
      dispatch(gotoBlockRootAction());
      return;
    }

    let groupId: string | undefined = targetWidget.groupId;
    const paths: FolderPathInfo[] = [];
    do {
      const groupDetails = getGroupInfo(groupId, currentWidgets);
      const group: RecipeWidget | undefined = currentWidgets[groupId];

      if (!groupDetails) {
        break;
      }

      paths.push(groupDetails);

      groupId = group?.groupId;
    } while (groupId);

    const reversedPaths = paths.reverse();
    dispatch(setFolderPath(reversedPaths));
    dispatch(setCurrentWidgetAction(reversedPaths.pop()?.groupId || null ));
  }, [dispatch, recipe]);

  return navigateToWidgetPath;
};

export default useNavigateToWidgetPath;
