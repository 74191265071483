import { RecipeType } from '@kemu-io/kemu-types';
import { createAsyncThunk, ActionReducerMapBuilder, PayloadAction  } from '@reduxjs/toolkit';
import { NavigateFunction } from 'react-router-dom';
import { ThingMap } from '@kemu-io/kemu-core/types';
import { showGlobalNotification } from '../interfaceSlice';
import { RootState } from '@src/app/store';
import { generateRecipePackage } from '@common/recipeActions/saveRecipe';
import { setRecipeType, WorkspaceState } from '@src/features/Workspace/workspaceSlice';
import routes from '@common/routes';
import { getGlobalIntl } from '@src/assets/i18n/globalIntl';
import { AsyncRequestStatus } from '@src/types/core_t';

type SaveRecipeToFileActionResult = {
  blocks: ThingMap,
}

/**
 * Generates a recipe package to be saved to a file.
 * @returns the ArrayBuffer of the recipe or null if the recipe is not found
 */
export const saveRecipeToFileAction = createAsyncThunk('/workspace/saveRecipeToFile', async (options: {
  newName?: string,
  navigate: NavigateFunction,
  handle?: FileSystemFileHandle | null,
  saveHandle?: (handle: FileSystemFileHandle) => void,
}, thunkAPI): Promise<null | SaveRecipeToFileActionResult> => {
  const t = getGlobalIntl();
  const state = thunkAPI.getState() as RootState;
  const recipePoolId = state.workspace.currentRecipe.poolId;
  let handle = options.handle;

  if (!recipePoolId) {
    return null;
  }

  const { contents, storage, parsedContents: recipe } = await generateRecipePackage(recipePoolId, options.newName);
  // const dbId = recipe.id;
	// const storageSize = storage?.size || 0;
	// const checksum = storage?.checksum || '0';

  // const encoder = new TextEncoder();
	// const contentsBuffer = encoder.encode(contents);
	// const contentAb = contentsBuffer.buffer.slice(contentsBuffer.byteOffset, contentsBuffer.byteOffset + contentsBuffer.byteLength) as ArrayBuffer;

    if (!handle) {
      // @ts-expect-error missing types
      handle = await window.showSaveFilePicker({
        suggestedName: `${recipe.name || 'untitled'}.kemu`,
        types: [{
          description: 'Kemu Recipe',
          accept: {
            'application/json': ['.kemu']
          }
        }]
      });
    }


  // Create a FileSystemWritableFileStream to write to
  const writable = await (handle as FileSystemFileHandle).createWritable();

  // Write the contents
  await writable.write(contents);

  // Close the file and write the contents to disk
  await writable.close();

  if (options.saveHandle) {
    options.saveHandle(handle as FileSystemFileHandle);
  }

  options.navigate(routes.recipe.getDesktopRecipeRoute(recipe.id));

  thunkAPI.dispatch(showGlobalNotification({
    title: t('Recipe.SaveToFile.Success.Title'),
    message: t('Recipe.SaveToFile.Success.Message'),
    type: 'success',
  }));

  return {
    blocks: recipe.blocks,
  };
});


export const saveRecipeToFileReducer = (builder: ActionReducerMapBuilder<WorkspaceState>): void => {
  builder
    // .addCase(saveRecipeToFileAction.pending, (state) => {
    //   state.asyncOperation = {
    //     ...state.asyncOperation,
    //     // status: AsyncRequestStatus.loading,
    //     error: undefined,
    //   };
    // })
    .addCase(saveRecipeToFileAction.fulfilled, (state, action: PayloadAction<SaveRecipeToFileActionResult | null>) => {
      if (!action.payload) { return; }

      state.currentRecipe.type = RecipeType.Desktop;
      state.currentRecipe.isDefault = false;
      state.currentRecipe.openedWithErrors = false;
      state.openingRecipe = false;
      state.currentRecipe.blocks.collection = action.payload.blocks;
      state.currentRecipe.blocks.status = AsyncRequestStatus.completed;
    //   state.asyncOperation = {
    //     ...state.asyncOperation,
    //     // status: AsyncRequestStatus.completed,
    //   };
    })
    .addCase(saveRecipeToFileAction.rejected, (state, action) => {
      console.error(action.error);
      // thunkAPI.dispatch(showGlobalNotification({
      //   title: t('Recipe.SaveToFile.Error.Title'),
      //   message: t('Recipe.SaveToFile.Error.Message'),
      //   type: 'error',
      // }));
      // state.asyncOperation = {
      //   ...state.asyncOperation,
      //   status: AsyncRequestStatus.error,
      //   error: action.error,
      // };
    });
};
