import React, { useCallback, useEffect, useRef, CSSProperties, memo } from 'react';
import { BrowserJsPlumbInstance } from '@jsplumb/community';
import classNames from 'classnames';
import { GroupWidgetPort, PortType, Position, WidgetType } from '@kemu-io/kemu-core/types';
import { createWidgetPortIdentifier } from '@kemu-io/kemu-core/common/utils';
import { getEndpointSetting } from '../../common/jsPlumb/settings';
import { GATE_PORT_CLASS, SOURCE_PORT_CLASS, TARGET_PORT_CLASS, WIDGET_PORT_RADIUS } from '../../common/constants';
import { PortLocation } from '../../types/canvas_t';
import { generateDomId } from '../../common/utils';
import styles from './virtualPort.module.css';
import { getCategoryFromType } from '@common/widgetCategories';
import { KemuEndpointData } from '@src/types/core_t';

interface Props {
	// portIndex: number;
	port: GroupWidgetPort;
	isInner: boolean;
	gateId: string;
	blockId: string;
	recipeId: string;
	plumbInstance: BrowserJsPlumbInstance;
	updatePortPosition: (port: GroupWidgetPort, position: Position) => void;
	portLocation: 'Right' | 'Left';
}


const getEndpointConfig = (isTarget: boolean, id: string, customPosition: PortLocation) => {
	// top (LR), left (TB), (dx, dy) <== direction of curve
	const portSettings = getEndpointSetting(isTarget, customPosition, WIDGET_PORT_RADIUS);
	portSettings.portId = id;
	portSettings.parameters = {
		isTarget,
	};

	portSettings.uuid = id;
	return portSettings;
};


const getDefaultPosition = (portLocation: 'Right' | 'Left', index: number, position?: Position): Position => {
	if (position?.x !== undefined && position?.y !== undefined) { return position; }

	// Handle inputs
	if (portLocation === 'Right') {
		return {
			x: 100,
			y: 100 + (60 * index)
		};
	} else {
		return {
			x: 500,
			y: 100 + (60 * index)
		};
	}
};

const VirtualPort = (props: Props): React.JSX.Element => {
	const { gateId, plumbInstance, port, portLocation, updatePortPosition } = props;
	const containerRef = useRef<HTMLDivElement | null>(null);
	const canvasDomId = generateDomId(props.recipeId, props.blockId, props.gateId, port.name);
	const isOutput = portLocation === 'Right';
	const portType: PortType = isOutput ? 'innerOutput' : 'innerInput';

	const position = getDefaultPosition(props.portLocation, port.index, port.position);
	const gatePos: CSSProperties= { left: position.x, top: position.y };
	const newPortPosStr = JSON.stringify(position);
	const currentPortPosStr = JSON.stringify(port.position);
	// IMPORTANT: `port.type` is an array which reference changes between renders. We serialize it to a string
	// to prevent the buildPort from creating new references every time the component renders.
	const portDataTypeStr = JSON.stringify(port.type);

	// Adds the JS plumb port to the canvas element
	const buildPort = useCallback(() => {
		// const portId = getUniqueWidgetPortId(port.index, type, gateId, port.name);
		const portId = createWidgetPortIdentifier(gateId, portType, port.name);
		const portDataType = JSON.parse(portDataTypeStr);
		if (containerRef.current && plumbInstance) {
			const sourcePortSettings = getEndpointConfig(!isOutput, portId, portLocation);
			const portTypes = (Array.isArray(portDataType) ? portDataType : [portDataType]).map((arrType) => `type-${arrType}`);
			sourcePortSettings.cssClass = classNames(
				sourcePortSettings.cssClass,
				isOutput ? SOURCE_PORT_CLASS : TARGET_PORT_CLASS,
				'port',
				GATE_PORT_CLASS,
				`ctgry-${getCategoryFromType(WidgetType.widgetGroup)}`,
				...portTypes,
			);

			// This is used by the port debugging panel
      const endpointData: KemuEndpointData = {
        type: port.type,
        name: port.name,
        label: port.label,
        id: portId,
        jsonShape: port.jsonShape
      };

			const addedPort = plumbInstance.addEndpoint(containerRef.current, { ...sourcePortSettings });
			addedPort.setData(endpointData);
		}
	}, [plumbInstance, gateId, portDataTypeStr/* , port.index */, portLocation, port.name, portType, isOutput, port.label, port.jsonShape, port.type]);

	// Create ports and rebuild connections 
	useEffect(() => {
		const elRefId = containerRef.current?.id;
		if (elRefId && plumbInstance) {
			buildPort();
		}

		return () => {
			if (elRefId && plumbInstance) {
				plumbInstance.removeAllEndpoints(elRefId);
				// Remove connections making sure no event listeners are triggered (since we don't want 
				// the disconnection to be registered in the recipe)
				plumbInstance.deleteConnectionsForElement(elRefId, { fireEvent: false });
				delete plumbInstance.getManagedElements()[elRefId];
				// plumbInstance.unmanage(elRefId);
			}
		};
	}, [buildPort, plumbInstance, gateId]);

	// We update the widget's state and set the default position if missing
	useEffect(() => {
		if (newPortPosStr !== currentPortPosStr) {
			updatePortPosition(port, JSON.parse(newPortPosStr));
		}
	}, [newPortPosStr, currentPortPosStr, port, updatePortPosition]);

	return (
		<div
			className={styles.PortContainer}
			ref={containerRef}
			style={gatePos}
			id={canvasDomId}
		>
			{port.label || port.name}
		</div>
	);
};


export default memo(VirtualPort, (prevProps: Props, nextProps: Props) => {
	const areEqual =
		JSON.stringify(prevProps.port) === JSON.stringify(nextProps.port) &&
		prevProps.gateId === nextProps.gateId;
	return areEqual;
});
